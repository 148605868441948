/**
 *  Purpose: the modifications that are carried on the Payee Activity objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 // STATE
 // payeeActivity:{
 //  payeeActivityById:{},
 // }
 
 
 const payeeActivityReducer = (state = initialState.payeeActivity, action) => {
     const {type, payload} = action;
     let payeeActivityById = {...state.payeeActivityById}
     switch (type){
         case types.SAVE_PAYEE_ACTIVITY: {
             if(typeof payload.payeeActivity !== "object") {
                 logError(`payeeActivityReducer > SAVE_PAYEE_ACTIVITY: payeeActivity payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.payeeActivity.forEach(payeeActivity => {
                payeeActivityById[payeeActivity.id] = payeeActivity                                 
             })
             return {
                 ...state,
                 payeeActivityById,

             }
         }
 
         case types.LOGOUT: {
             return initialState.payeeActivity
         }
 
         default: return state
     }
 }
 
 export default payeeActivityReducer