import React from "react"
import { Link } from "react-router-dom"
const NotFound = () => {
    return (
        <div className="width-fit-content margin-auto text-align-center display-width-400px padding-1em">
            <div className="h1 margin-bottom-1em">
                404
            </div>
            <div className="h2 margin-bottom-1em">
                The page you were looking for does not exist
            </div>
            <div className="margin-bottom-1em">Please check your url. There could be a typo in the merchant's handle or payment id</div>
            <button className="button secondary"><Link to="/">Go Back To Home</Link></button>
        </div>
    )
}

export default NotFound