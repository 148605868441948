import React from "react";
import ListByDate from "../../components/ListByDate";
import Icon from "../../components/Icon";

import {connect} from "react-redux"
import ReceiptActivityListItem from "../../components/ReceiptActivityListItem";

const PayerReceiptDateList = ({title="Receipts", receipts, device}) => {
    //filter the receipts list to only those for this device order descrending
    const receiptsList = Object.values(receipts.receiptsById)
                              .filter(
                               r => r.deviceId === device.id
                              )
                              .sort((rA, rB) => rB.createdAt - rA.createdAt)
    return (
            <ListByDate 
                title={title}
                itemList={receiptsList}
                renderItem={
                            receipt => <ReceiptActivityListItem 
                                            receipt={receipt} 
                                        />
                            }
                placeholder={<div className="flex-center padding-1em margin-top-05em background-color-light-grey border-radius-8px color-secondary"><div className="icon-left display-flex align-items-center"><Icon icon="receipt"/> No Receipts</div></div>}
            />
    )
}

const mapStateToProps = state => ({
    device: state.device,
    receipts: state.receipts,
})

export default connect(mapStateToProps)(PayerReceiptDateList)