import React from "react"
import LoginForm from "../../forms/LoginForm"

import {Helmet} from "react-helmet";
import styles from "./Login.module.css"

const Login = () => {
    const redirectFrom = ""
    return (
        <div>
            <Helmet>
                <title>Login - Shopdm Pay</title>
            </Helmet>
            <div className={styles.loginForm}>
                <LoginForm redirectTo={redirectFrom} />
            </div>
        </div>
    )
}

export default Login