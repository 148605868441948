import React, {useState, useEffect, useCallback} from "react"
import {useParams, useSearchParams, Navigate} from "react-router-dom"

import OneTimePaymentForm from "../../forms/OneTimePaymentForm";
import FacCardPaymentModal from "../../containers/FacCardPaymentModal"
import NotFound from "../../containers/NotFound";
import AvatarIcon from "../../components/AvatarIcon";

import {DEBIT_OR_CREDIT_CARD_ID, PAYMENT_OBJECT_TYPE_ONE_TIME_PAYMENT} from "../../constants/payment"
import {ONE_TIME_PAYMENT_STATUS_CANCELLED} from "../../constants/oneTimePayments"
import {
    PAYEE_ERROR_TYPE_MONTHLY_LIMIT_EXCEEDED,
    PAYEE_ERROR_TYPE_TRANSACTION_LIMIT_EXCEEDED
} from "../../constants/payees";

import {Helmet} from "react-helmet";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import ConfirmPhoneNumberModal from "../../containers/ConfirmPhoneNumberModal";

const Pay = ({payees, oneTimePayments, user, device, actions, activeAuthenticationData}) => {
    const params = useParams()
    const {payeeHandleId} = params
    const payee = payees.payeesById[payees.payeeIdByHandleId[payeeHandleId]]
    //get the search params to check whether this one time payment is linked to a payee product
    const [searchParams] = useSearchParams()
    const [payeeProductId] = useState(searchParams.has("p") ? searchParams.get("p") : "")
    //listen to payee indicated by the payeeHandleId from the url params
    const [payeeListener, setPayeeListener] = useState(() => () => {
    })
    //track loading of payee to know if payee does not exist or is just not yet loaded
    const [payeeLoaded, setPayeeLoaded] = useState(null)
    //listen to they payee anytime the payee handle id changes
    const listenToPayee = async () => {
        if (!payee) actions.toggleLoading(true)
        //listen to payee by also load it directly for speed
        actions.fetchSaveMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        const newPayeeListener = await actions.fetchSubscribeToMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        setPayeeListener(() => newPayeeListener)
    }
    useEffect(() => {
        if (payeeHandleId) listenToPayee()
        return () => {
            if (typeof payeeListener === "function") payeeListener()
        }
    }, [payeeHandleId])
    useEffect(() => {
        if (payeeLoaded) {
            actions.toggleLoading(false)
        }
    }, [payeeLoaded])
    useEffect(() => {
        return () => {
            actions.toggleLoading(false)
        }
    }, [])
    const [cardPaymentModalOpen, setCardPaymentModalOpen] = useState(false)
    //subscribe to the one-time payment after it is created via the one-time payment form
    const [oneTimePaymentId, setOneTimePaymentId] = useState(null)
    const [oneTimePaymentListener, setOneTimePaymentListener] = useState(() => () => {
    })
    const listenToOneTimePayment = async () => {
        actions.toggleLoading(true)
        const newOneTimePaymentListener = await actions.fetchSubscribeToOneTimePayment(oneTimePaymentId)
        setOneTimePaymentListener(() => newOneTimePaymentListener)
    }

    // Subscribing to OTP changes. Needed for redirection to the receipt page upon payment success.
    useEffect(() => {
        if (oneTimePaymentId) listenToOneTimePayment()
        return () => {
            if (typeof oneTimePaymentListener === "function") oneTimePaymentListener()
        }
    }, [oneTimePaymentId])

    const oneTimePayment = oneTimePayments.oneTimePaymentsById[oneTimePaymentId]
    const oneTimePaymentCancelled = (oneTimePayment && oneTimePayment.closedAt && oneTimePayment.currentStatus === ONE_TIME_PAYMENT_STATUS_CANCELLED)

    // Subscribing to OTP getting canceled.
    // Despite OTP being created in a ready-to-pay state, at the stage of payment,
    // the payee's limit can change and exceed the limits. Therefore, the OTP can get canceled at that stage
    useEffect(() => {
        if (oneTimePaymentCancelled) {
            actions.toggleLoading(false)
            if (typeof oneTimePaymentListener === "function") oneTimePaymentListener()
            if (oneTimePayment.errorType === PAYEE_ERROR_TYPE_TRANSACTION_LIMIT_EXCEEDED || oneTimePayment.errorType === PAYEE_ERROR_TYPE_MONTHLY_LIMIT_EXCEEDED) alert(`Error: ${payee.name} is not able to accept transactions of this size right now. Try a different amount or ask them to contact the Shopdm Pay team.`)
        }
    }, [oneTimePaymentCancelled])

    const handleOneTimePaymentCreated = useCallback(id => {
        setCardPaymentModalOpen(true)
        setOneTimePaymentId(id)
    }, []);

    //redirect from the Pay page to the receipt page if the one time payment's receiptId changes to be truthy
    const receiptId = oneTimePayment && oneTimePayment.receiptId ? oneTimePayment.receiptId : ""
    if (receiptId) return <Navigate to={`/${payeeHandleId}/r/${receiptId}`}/>
    if (payeeLoaded && !payee) return <NotFound/>
    //if the payee has not yet been loaded, and one or both is falsy
    if (!payee) return ""
    if (!payee.isActive) return <div>payee is not active</div>
    const {payerData={}} = oneTimePayment || {}
    const {id:payerId="", email="", phoneNumber=""} = payerData
    return (
        <div>
            <Helmet>
                <title>Pay {payee.name} on Shopdm Pay </title>
            </Helmet>
            <div className="margin-auto width-fit-content">
                <div className={`card display-width-400px card-shadow`}>
                    <div className="flex-center"><AvatarIcon name={payee.name} imageUrl={payee.logoImageUrlMed}/></div>
                    <div className="h2 text-align-center">Pay {payee.name}</div>
                    <OneTimePaymentForm
                        payeeId={payee.id}
                        clearFieldsOnSubmit={false}
                        toggleLoadingOffAfterSubmit={false}
                        onSuccess={handleOneTimePaymentCreated}
                        transactionLimitXcd={payee.transactionLimitXcd}
                        payeeProductId={payeeProductId}
                    />
                </div>
                <div className="margin-top-1em card display-width-400px card-shadow font-size-14px color-grey">
                    <div>{payee.name} uses Shopdm Pay to accept card payments securely online.</div>
                    <div className="margin-top-05em">Shopdm Pay is a product of Dom Software Ltd, a company registered
                        in the Commonwealth of Dominica (No. 2019/C0074)
                    </div>
                </div>
            </div>
            {
                cardPaymentModalOpen && oneTimePayment && oneTimePayment.ready ?
                    <FacCardPaymentModal
                        isOpen={Boolean(oneTimePayment)}
                        closeModal={() => setCardPaymentModalOpen(false)}
                        onSuccess={() => actions.toggleLoading(true)}
                        amount2DecimalPlaces={oneTimePayment.totalPlusFeesXcd}
                        userId={user.id} //provide user id if payer is logged in
                        userName={`${user.firstName} ${user.lastName}`} //provider user name if payer is logged in
                        email={email}
                        phoneNumber={phoneNumber}
                        payerId={payerId}
                        deviceId={device.id}
                        objectId={oneTimePaymentId}
                        objectType={PAYMENT_OBJECT_TYPE_ONE_TIME_PAYMENT}
                        reference={oneTimePayment.reference}
                        paymentProviderId={DEBIT_OR_CREDIT_CARD_ID}
                    />
                    :
                    null
            }
            {
                Boolean(activeAuthenticationData) ?
                    <ConfirmPhoneNumberModal
                        onPaymentSuccess={handleOneTimePaymentCreated}
                        authRequestId={activeAuthenticationData.authRequestId}
                        oneTimePayment={activeAuthenticationData.oneTimePayment}
                        closeModal={() => actions.clearPayerAuthentication()}
                    />
                    :
                    null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    device: state.device,
    payees: state.payees,
    oneTimePayments: state.oneTimePayments,
    activeAuthenticationData: state.payers.activeAuthenticationData,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Pay)