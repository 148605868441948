import React from "react";
import styles from "./MobileNavigationBar.module.css"

const MobileNavigationBar = ({children}) => {
    return (
        <div className={`mobileOnly ${styles.container}`}>
            <div className="mobileNavigationBar">
                {children}
            </div>
        </div>
    )
}

export default MobileNavigationBar