/**
 * Function to remove the token from the payer object for usage when copying the payer from localStorage to state.
 * @param payerData
 * @returns payerData without the token property
 */
export const removeTokenFromPayer = (payerData) => {
    if (!payerData) {
        return payerData;
    }

    // eslint-disable-next-line no-unused-vars
    const {token, ...payer} = payerData;

    return payer
}