/**
 *  Purpose: the modifications that are carried on the Invoice objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // invoices:{
 //   invoicesById:{},
 // }
 
 
 const invoicesReducer = (state = initialState.invoices, action) => {
     const {type, payload} = action;
     let invoicesById = {...state.invoicesById}
     switch (type){
         case types.SAVE_INVOICES: {
             if(typeof payload.invoices !== "object") {
                 logError(`invoicesReducer > SAVE_INVOICES: invoices payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.invoices.forEach(invoice => {
                invoicesById[invoice.id] = invoice
             });
             return {
                 ...state,
                 invoicesById,
             }
         }
 
         case types.CREATE_INVOICE: {
             if(typeof payload.invoice !== "object") {
                 logError(`invoicesReducer > CREATE_INVOICE: invoice payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             invoicesById[payload.invoice.id] = payload.invoice
             return {
                 ...state,
                 invoicesById
             }
         }
         default: return state
     }
 }
 
 export default invoicesReducer