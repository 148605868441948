import { query, collection, where, getDocs} from "firebase/firestore";

export const getFirestoreObjectsByIdList = async (firestore, ids=[], collectionName="", constraints=[]) => {
    let queries = [];
    for(let i = 0; i < ids.length; i += 10) {
    queries.push(query(
        collection(firestore, collectionName),
        where("id", "in", ids.slice(i, i + 10)),
        ...constraints
        
    ));
    }
    let docSnapshots = [];
    for(let i = 0; i < queries.length; i++) {
        docSnapshots.push(getDocs(queries[i]));
    }
    docSnapshots = await Promise.all(docSnapshots).catch(e => console.warn(e));
 
    const docs = [...new Set([].concat(...docSnapshots.map(
            (snapshot) => snapshot.docs.map(docRef => ({...docRef.data()})))))];
    return docs
}