import {firebaseApp, baseUrl} from "../config/firebase"
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_RECEIPTS = 'SAVE_RECEIPTS'
export const SET_LAST_RECEIPT_EMAIL = 'SET_LAST_RECEIPT_EMAIL'

export const saveReceipts = receipts => {
    return {
        type: SAVE_RECEIPTS,
        payload: {
            receipts
        }
    }
}

export const setLastReceiptEmail = lastReceiptEmail => {
    return {
        type: SET_LAST_RECEIPT_EMAIL,
        payload: {
            lastReceiptEmail
        }
    }
}

export const fetchSaveReceipt = (receiptId) => {
    /**
      * Purpose: retrieve one receipt from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const receiptRef = doc(firestore, "receipts", receiptId)
                                
    return async (dispatch) => {
        try {
            const docRef = await getDoc(receiptRef)
            const receipt = docRef.exists() ? {...docRef.data()} : null
             if (receipt) dispatch(saveReceipts([receipt]))
            return receipt
        } catch (e){
            const message = `action > receipts > fetchSaveReceipt: Failed to save receipt ${receiptId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchEmailReceipt = (
    email,
    receiptId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    return async (dispatch, getState) => {
        try {
            const {device} = getState()
            const link = `${baseUrl}v1/receipts/email`
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    email,
                    receiptId,
                    deviceId: device.id
                })
            })
            if (!response.ok) {
                throw new Error("something went wrong")
            }
            dispatch(setLastReceiptEmail(email))
            onSuccess(email)
            return true
        } catch (e) {
            e.message = `${e.message}`
            logError(e)
            onError(e)
            return false
        }
    }
}