import React from "react"

class EllipsisText extends React.PureComponent {
    
    state = {
        showMore: false
    }

    static defaultProps = {
        text: "",
        maxWidth: 150,
        charDisplayLength: 100
    }

    handleShowMore = () => this.setState({showMore: true})
    handleHide = () => this.setState({showMore: false})

    render(){
        const {showMore} = this.state
        const {text, maxWidth, charDisplayLength} = this.props
        if (!text || typeof text !== "string") return ""
        const textStyle = {maxWidth}
        const displayText = text.length <= charDisplayLength ?
                            text
                            :
                            showMore ?
                            <span>{text} <a className="font-size-14px white-space-nowrap cursor-pointer" onClick={this.handleHide}>- hide</a></span>
                            :
                            <span>{text.substring(0, charDisplayLength)}...<a className="font-size-14px white-space-nowrap cursor-pointer" onClick={this.handleShowMore}>more</a></span>
        return (
                <span 
                    style={textStyle} 
                    className="display-inline-block"
                    
                >
                    {displayText}
                </span>  
        )
    }
}

export default EllipsisText