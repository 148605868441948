/**
 *  Purpose: the modifications that are carried on the User object for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 // STATE
//  firstName: "",
//  lastName: "",
//  email: "",
//  phoneNumber: "",
//  authenticated:false,
//  lastLogin: 0,
//  activePayeeAccountId: ""
 // }
 
 
 const userReducer = (state = initialState.user, action) => {
     const {type, payload} = action;
     switch (type){
         case types.LOGIN: {
             return {
                 ...state,
                 ...payload.user
             }
         }
         case types.SAVE_USER: {
             let {user} = payload
             if (!user) user = {} //stops crash during no internet
             return {
                 ...state,
                 ...user
             }
         }
         case types.LOGOUT: {
             return initialState.user
         }
         
         case types.SET_ACTIVE_PAYEE_ACCOUNT:{
            if(typeof payload.activePayeeAccountId !== "string") {
                logError(`userReducer > SET_ACTIVE_PAYEE_ACCOUNT: activePayeeAccountId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                activePayeeAccountId: payload.activePayeeAccountId
            }
        }

         case types.CHANGE_ENVIRONMENT: {
             return initialState.user
         }
         default: return state
    }
 }
 
 export default userReducer