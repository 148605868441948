import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot} from "firebase/firestore"

import { logError } from "../utils/errorHandlingUtils"

export const SAVE_PAYEE_PRODUCTS = 'SAVE_PAYEE_PRODUCTS'

export const savePayeeProducts = payeeProducts => {
    return {
        type: SAVE_PAYEE_PRODUCTS,
        payload: {
            payeeProducts
        }
    }
}

export const fetchSubscribeToPayeeProduct = (payeeProductId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee product from the firestore database
      * Note: the onSnapshot below watches for changes to the payee product on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeProductRef = doc(firestore, "payeeProducts", payeeProductId)
                                
    return async (dispatch) => {
        try {
            const payeeProductListener = await onSnapshot(payeeProductRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee product from the snapshot
                    const payeeProduct = {...docRef.data()}
                    dispatch(savePayeeProducts([payeeProduct]))
                    onLoad(payeeProduct)
                } 
            )
            return payeeProductListener
        } catch (e){
            const message = `action > payeeProducts > fetchSubscribeToPayeeProducts: Failed to subscribe to payee product`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}