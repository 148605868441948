import React from "react"

import { calculateTransactionFees } from "../../utils/feeUtils";
import { numberToCurrencyString } from "../../utils/currencyUtils"

import {connect} from "react-redux"

const CustomerFeeCalculator = ({
    label="- Customer's processing Fee",
    left="",
    payeeId,
    totalXcd,
    payees,
}) => {
    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    const {senderFee = 0} = payee
    if (!senderFee || isNaN(senderFee)) return ""
    if (!totalXcd) return ""
    const {senderFeeXcd} = calculateTransactionFees(totalXcd, {senderFee})
    if (!senderFeeXcd) return ""
    return (
        <div className="">
            {left}${numberToCurrencyString(senderFeeXcd, {showDoubleZeroCents: true})} <span>{label}</span>
        </div>
    )
}

const mapStateToProps = state => ({
    payees: state.payees,
})

export default connect(mapStateToProps)(CustomerFeeCalculator)