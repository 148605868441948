/**
 *  Purpose: the modifications that are carried out on the Disbursement objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {STATISTICS_IDENTIFIER} from "../constants/analysis"
 
 // STATE
 // disbursements:{
 //  disbursementsById:{},
 // }
 
 
 const disbursementsReducer = (state = initialState.disbursements, action) => {
     const {type, payload} = action;
     let disbursementsById = {...state.disbursementsById}
 
     switch (type){
         case types.SAVE_DISBURSEMENTS: {
             if(typeof payload.disbursements !== "object") {
                 logError(`disbursementsReducer > SAVE_DISBURSEMENTS: disbursements payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.disbursements.forEach(disbursement => {
                 if (disbursement.id !== STATISTICS_IDENTIFIER){
                     disbursementsById[disbursement.id] = disbursement
                 }
             })
             
             return {
                 ...state,
                 disbursementsById,
             }
         }
 
         case types.LOGOUT: {
             return initialState.disbursements
         }
         
         default: return state
     }
 }
 
 export default disbursementsReducer