import React from "react"
import {Link} from "react-router-dom"
import Navigation from "../../components/Navigation"
import Icon from "../../components/Icon"

const The404Page = () => {
    return (
        <div>
            <Navigation backgroundColor="var(--white)">
                <ul className="navMain">
                    <li>
                        <button className="button secondary"><Link to="/" className=" icon icon-left flex-center"><Icon icon="arrow-back"/> Back To Home</Link></button>
                    </li>
                </ul>
            </Navigation>
            <div className="width-fit-content margin-auto text-align-center display-width-400px padding-1em">
                <div className="h1 margin-bottom-1em">
                    404
                </div>
                <div className="h2 margin-bottom-1em">
                    The page you were looking for does not exist
                </div>
                <div className="margin-bottom-1em">Please check your url. If you were trying to go to a merchant's payment page there might be a typo in their handle</div>
                <button className="button secondary"><Link to="/">Go Back To Home</Link></button>
            </div>
        </div>
    )
}

export default The404Page