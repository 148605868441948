import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import React, {useCallback, useRef, useState} from "react";

import * as actions from "../../actions";

import Modal from "../../components/Modal";
import {callCreateOneTimePayment} from "../../actions";
import styles from "./ConfirmPhoneNumberModal.module.css";
import {useThrottledFunction} from "../../hooks/useThrottledFunction";

const SMSCodeConfirmationModal = ({
                                      closeModal,
                                      oneTimePayment,
                                      onPaymentSuccess,
                                      authRequestId,
                                      numOfDigits=6
                                  }) => {
    const dispatch = useDispatch();
    const inputsRef = useRef([]);
    const [INITIAL_CODE] = useState(new Array(numOfDigits).fill(""))                                
    const [code, setCode] = useState(INITIAL_CODE);
    const [confirmationError, setConfirmationError] = useState(null);
    const [isRequestingNewCode, setIsRequestingNewCode] = useState(false);
    //auto focus the previous input if the user presses backspace                               
    const handleKeyDown = useCallback((e, index) => {
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            inputsRef.current[index - 1].focus();
        }
    }, [code]);

    const handleConfirmSMSCode = useCallback(async (enteredCode) => {
        let payerId
        try {
            dispatch(actions.toggleLoading(true))
            payerId = await dispatch(
                actions.callConfirmSMSCode(authRequestId, enteredCode, oneTimePayment.payerData)
            );
        } catch (e) {
            let message;
            switch (e.code) {
                case "unauthenticated":
                    message = "Invalid verification code"
                    break;
                case "deadline-exceeded":
                    message = "Sorry, your code timed out.\nPress 'resend code' to try again"
                    break;
                case "closed":
                    message = "Sorry, your code is no longer valid.\nPress 'resend code' to try again"
                    break;
                case "resource-exhausted":
                    message = "Max attempts exceeded.\nPress 'resend code' to try again"
                    break;
                case "not-found":
                default:
                    message = "Unknown error. Please contact the Shopdm Pay team"
            }
            setConfirmationError({code: "unknown", message})
            return;
        } finally {
            dispatch(actions.toggleLoading(false))
        }
        dispatch(actions.toggleLoading(true))
        await dispatch(callCreateOneTimePayment(
            oneTimePayment.id,
            {
                ...oneTimePayment,
                payerData: {...oneTimePayment.payerData, id: payerId}
            },
            onPaymentSuccess,
        ));
        dispatch(actions.toggleLoading(false))
    }, [dispatch, authRequestId, onPaymentSuccess, oneTimePayment]);

    const handleChange = useCallback((value, index) => {
        // Only resetting the error if it was an invalid code or unknown
        if (confirmationError && (
            confirmationError.code === 'unknown' || confirmationError.code === 'unauthenticated'
        )) {
            setConfirmationError(null)
        }

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        //automatically focus on the next input
        if (value && index < (numOfDigits - 1)) {
            inputsRef.current[index + 1].focus();
        }

        if (newCode.every(digit => digit !== '')) {
            handleConfirmSMSCode(newCode.join(''));
        }
    }, [code, confirmationError, handleConfirmSMSCode]);

    //allow the user to paste the code into the code input
    const handlePaste = useCallback((e) => {
        const pasteData = e.clipboardData.getData('text').trim();
        if (pasteData.length === numOfDigits && /^[0-9]+$/.test(pasteData)) {
            const newCode = pasteData.split('');
            setCode(newCode);

            newCode.forEach((digit, index) => {
                inputsRef.current[index].value = digit;
            });

            inputsRef.current[(numOfDigits - 1)].focus();
            handleConfirmSMSCode(pasteData);
        }
        e.preventDefault();
    }, [handleConfirmSMSCode]);

    // Helps avoid spamming us with new code requests.
    const handleResendCode = useThrottledFunction(
        async () => {
            // If another new code request is already in progress, do nothing
            if (isRequestingNewCode) {
                return;
            }

            try {
                dispatch(actions.toggleLoading(true));
                setIsRequestingNewCode(true)
                setConfirmationError(null);
                setCode(INITIAL_CODE)
                // This thunk action will update the authRequestId if new auth request is initiated successfully
                await dispatch(actions.callInitiatePhoneAuthorizationRequest(oneTimePayment));
            } catch (e) {
                setConfirmationError({
                    code: "unknown",
                    message: "Unknown error. Please, contact administrators"
                })
            } finally {
                dispatch(actions.toggleLoading(false));
                setIsRequestingNewCode(false);
            }
        },
        5000
    );
    //set the size of the inputs container, based on the number of digits in the code
    const smsInputsContainerWidth = 48 * numOfDigits
    return (
        <Modal
            isOpen
            closeModal={closeModal}
            className={`height-fit-content ${styles.modal}`}
            innerClassName={`${styles.modalInnerContainer}`}
            overlayClassName={`flex-center ${styles.modalOverlay}`}
        >
            <div className={styles.container}>
                <div className="h2">Enter the {numOfDigits}-digit code</div>
                <div className='small margin-bottom-1em'>Check your texts. We sent it via SMS</div>
                <div style={{width: smsInputsContainerWidth}} className={`${styles.smsCodeInputs} margin-bottom-1em`}>
                    {code.map((digit, index) => (<input
                        key={index}
                        type={"text"}
                        inputMode='numeric'
                        maxLength={1}
                        value={digit}
                        onPaste={handlePaste}
                        autoComplete={"one-time-code"}
                        onFocus={e => e.target.select()}
                        ref={el => inputsRef.current[index] = el}
                        onChange={e => handleChange(e.target.value, index)}
                        onKeyDown={e => handleKeyDown(e, index)}
                    />))}
                </div>
                {confirmationError && (
                    <div className="errorText text-align-center">
                        {confirmationError.message.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br/>
                            </React.Fragment>
                        ))}
                    </div>
                )}
                <button onClick={handleResendCode} className={`button secondary width-80pct margin-top-05em`}>
                    Resend Code
                </button>
            </div>
        </Modal>
    );
};

SMSCodeConfirmationModal.propTypes = {
    onPaymentError: PropTypes.func,
    onPaymentSuccess: PropTypes.func,

    closeModal: PropTypes.func.isRequired,
    authRequestId: PropTypes.string.isRequired,
    oneTimePayment: PropTypes.object.isRequired,
};

SMSCodeConfirmationModal.defaultProps = {
    onPaymentSuccess: () => {
    }
};

export default SMSCodeConfirmationModal;
