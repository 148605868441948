import React from "react"
import AvatarIcon from "../../components/AvatarIcon"
import Icon from "../../components/Icon"
import DropdownMenu from "../../components/DropdownMenu"

import Logout from "../Logout"

import { capitalizeAllWords } from "../../utils/stringUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import styles from "./PayeeAccountMenu.module.css"
import {alphabeticalSort} from "../../utils/stringUtils"

const PayeeAccountMenu = ({user, payees, actions}) => {
    const {activePayeeAccountId="", authorizedPayeeAccountIds={}} = user
    const payeeId = activePayeeAccountId
    if (!user || !user.authenticated) return ""
    const activePayee = payees.payeesById[payeeId]
    if (!activePayee) return ""
    //compose the dropdown list
    const menuItems = []
    Object.keys(authorizedPayeeAccountIds)
          .map(payeeId => payees.payeesById[payeeId])
          .sort((pA, pB) => alphabeticalSort(pA.name, pB.name))
          .forEach(payee => {
            if (!payee) return
            if(payee.id === activePayeeAccountId) return
            menuItems.push({
                label: payee.name,
                icon: "account-circle",
                onClick: () => actions.fetchSetActivePayeeAccount(user.id, payee.id)   
            })
          })
    menuItems.push(
        {
            label: <Logout>
                        <div className="display-flex icon-left">
                            <Icon icon="logout"/>
                            <div>
                                <div>Logout</div>
                                <div className="font-size-14px color-grey">{user.email}</div>
                            </div>
                        </div>
                    </Logout>
        }
    )
    return (
            <div className={styles.container}>
                <DropdownMenu
                    menuItems={menuItems}
                    MenuHeader={() => <div className="h3 padding-05em">{activePayee.name}</div>}
                >
                    <AvatarIcon name={activePayee.name} imageUrl={activePayee.logoImageUrlMed}/>
                    <div className={styles.payeeName}>{capitalizeAllWords(activePayee.name)}</div>
                </DropdownMenu>
            </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeAccountMenu)