import React from "react"
import {Outlet, Link, Navigate} from "react-router-dom"
import Navigation from "../../components/Navigation"
import Footer from "../../containers/Footer"

import {connect} from "react-redux"

function MainLayout ({user}) {
    if (user && user.authenticated) return <Navigate replace to="/home" />
    return (
        <div>
            <Navigation backgroundColor="var(--white)">
                <ul className="navMain">
                    <li>
                        <Link to="/"><button className="button transparent">Home</button></Link>
                    </li>
                    <li>
                        <Link to="/login"><button className="button gradient">Log in</button></Link>
                    </li>
                    {/* <li>
                        <Link to="/create-account"><button className="button transparent">Create Account</button></Link>
                    </li> */}
                </ul>
            </Navigation>
            <main>
            <Outlet />
            </main>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
})
  export default connect(mapStateToProps)(MainLayout)