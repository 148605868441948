import React, {useState} from "react"
import styles from "./AddressInput.module.css"
import { COUNTRY_CODES } from "../../constants/country"
import {alphabeticalSort} from "../../utils/stringUtils"
const AddressInput = ({
    address= {
        line1: "",
        city: "",
        countryName: "Dominica",
        countryCode: "DM",
        zipCode: ""
    }, 
    onChange=()=>{}
}) => {
    const [countryMap] = useState(
        COUNTRY_CODES.reduce((map, c) => {
            map[c.code] = c
            return map
        }, {})
    ) 
    const [sortedCountryCodes] = useState(
        COUNTRY_CODES.sort((cA, cB) => alphabeticalSort(cA.name, cB.name))
    ) 
    const handleChange = (value, key) => {
        address = {...address}
        address[key] = value
        if (key === "countryCode"){

            const countryName = countryMap[value].name
            address.countryName = countryName
            address.zipCode = ""
        }
        onChange(address)
    }
    //find country
    const country = countryMap[address.countryCode]
    return (
        <div className={styles.container}>
            <input 
                value={address.line1}
                className="input" 
                onChange={e => handleChange(e.target.value, "line1")}
                placeholder="Street address"
            />
            <input 
                value={address.city}
                className="input"  
                onChange={e => handleChange(e.target.value, "city")}
                placeholder="City / Town"
            />
            <div className={styles.bottomRow}>
                <select
                    value={address.countryCode}
                    className={`input ${country && country.noZipCode ? styles.noZipCode : ""}`}
                    onChange={e => handleChange(e.target.value, "countryCode")}
                >
                    {
                        sortedCountryCodes.map(
                            country => <option key={country.code} value={country.code}>{country.name}</option>
                        )
                    }
                </select>
                <input 
                    value={address.zipCode}
                    className={`input ${country && country.noZipCode ? styles.noZipCode : ""}`}
                    onChange={e => handleChange(e.target.value, "zipCode")}
                    placeholder="ZIP / Postal code"
                />
            </div>
        </div>
    )
}

export default AddressInput