/**
 *  Purpose: the modifications that are carried on the cardAuthorizationRequests object for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // cardAuthorizationRequests:{
     // authRequestsById: {},
 // }
 
 const cardAuthorizationRequestsReducer = (state = initialState.cardAuthorizationRequests, action) => {
     const {type, payload} = action;
     let authRequestsById = {...state.authRequestsById}
 
     switch (type){
 
         case types.SAVE_CARD_AUTHORIZATION_REQUESTS: {
             if(!Array.isArray(payload.authRequests)) {
                 logError(`cardAuthorizationRequestsReducer > SAVE_CARD_AUTHORIZATION_REQUESTS: authRequests payload is not an array ${JSON.stringify(payload)}`)
                 return state
             }
             payload.authRequests.forEach(authRequest => {
                 if (!authRequest) return
                 authRequestsById[authRequest.id] = authRequest
             });
             return {
                 ...state,
                 authRequestsById,
             }
         }
         case types.LOGOUT: {
             return initialState.cardAuthorizationRequests
         }
 
         default: return state
     }
 }
 
 export default cardAuthorizationRequestsReducer