/**
 *  Purpose: the modifications that are carried on the Device object for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // device: {
     // cityName: "",
     // deviceType: "",
     // countryCode: "",
     // lastSignedInUserId: "",
     // latLon: "",
     // timezone: "",
     // mobileOSType: "",
     // firstVisitedAt: 0,
     // firstVisitDate: "",
     // lastVisitedAt: 0,
     // lastVisitDate: "",
     // totalVisits: 0,
     // lastSessionId: "",
 // }
 
 
 const deviceReducer = (state = initialState.device, action) => {
     const {type, payload} = action;
     switch (type){
 
         case types.CREATE_DEVICE: {
             if(typeof payload.device !== "object") {
                 logError(`deviceReducer > CREATE_DEVICE: device payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             return {
                 ...state,
                 ...payload.device
             }
         }
 
         case types.UPDATE_DEVICE: {
             if(typeof payload.deviceUpdate !== "object") {
                 logError(`deviceReducer > UPDATE_DEVICE: deviceUpdate payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             return {
                 ...state,
                 ...payload.deviceUpdate
             }
         }
 
         case types.LOGIN: {
             if(typeof payload.user !== "object") {
                 return state
             }
             return {
                 ...state,
                 lastSignedInUserId: payload.user.id
             }
         }
         default: return state
     }
 }
 
 export default deviceReducer