import {getFunctions, httpsCallable} from "firebase/functions";
import {logError, simplifyCallableErrorCode} from "../utils/errorHandlingUtils"

export const SAVE_PAYER = 'SAVE_PAYER'
export const START_PAYER_AUTHENTICATION = 'START_PAYER_AUTHENTICATION'
export const CLEAR_PAYER_AUTHENTICATION = 'CLEAR_PAYER_AUTHENTICATION'

export const savePayer = (payerData) => {
    return {
        type: SAVE_PAYER,
        payload: {
            payerData,
        }
    }
}

export const startPayerAuthentication = (payerAuthenticationData) => {
    return {
        type: START_PAYER_AUTHENTICATION,
        payload: {
            payerAuthenticationData,
        }
    }
}

export const clearPayerAuthentication = () => {
    return {
        type: CLEAR_PAYER_AUTHENTICATION
    }
}

export const callInitiatePhoneAuthorizationRequest = (
    oneTimePaymentData,
) => {
    /**
     * manually resend the phone authorization request
     */
    const functions = getFunctions();
    const {
        payerData: {id: payerId, phoneNumber}, createdByDeviceId: deviceId
    } = oneTimePaymentData;

    return async (dispatch) => {
        try {
            const response = await httpsCallable(functions, 'initiatePhoneAuthorizationRequest')({
                payerId, deviceId, phoneNumber
            });

            if (response.data.success) {
                dispatch(startPayerAuthentication({
                    oneTimePayment: oneTimePaymentData,
                    authRequestId: response.data.authRequestId,
                }))
            }

            return response.data;
        } catch (e) {
            const message = `action > oneTimePayments > callInitiatePhoneAuthorizationRequest: Failed to initiate auth request. Phone number: ${phoneNumber}`;
            logError(`${message}. ${e.message}`);

            throw e;
        }
    };
};

export const callConfirmSMSCode = (authRequestId, enteredCode, payerData) => {
    const functions = getFunctions();
    return async (dispatch) => {
        let response;
        try {
            response = await httpsCallable(functions, 'confirmSMSCode')({
                authRequestId,
                enteredCode,
                payerData
            });
        } catch (e) {
            const errorWithSimplifiedCode = simplifyCallableErrorCode(e)
            // Only logging unexpected errors, rest is users making mistakes
            if (!errorWithSimplifiedCode.code || errorWithSimplifiedCode.code === 'unknown') {
                logError(
                    `${e.message} action > oneTimePayments > callConfirmSMSCode: Failed to initiate auth request. authRequestId: ${authRequestId}`
                )
            }

            throw errorWithSimplifiedCode;
        }
        const {data: {success, token, payerId}} = response;

        // Backend would either throw an exception or return success.
        // This case is unlikely unless something changed.
        if (!success || !token || !payerId) {
            logError(`Unknown error confirming SMS for auth request ${authRequestId}. API returned success: false`)
            throw Error("Unknown error confirming payer SMS")
        }
        //NOTE: this is the only time the token is written to redux
        //NOTE: the local Payer data is what is written to redux, on verification, local payer data will have no id, 
        //      so we add in the id returned from the server
        await dispatch(savePayer({token, ...payerData, id: payerId}));
        dispatch(clearPayerAuthentication())

        return payerId;
    };
};