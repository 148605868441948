import React from "react"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const Logout = ({actions, children, user}) => {
    if (!user.authenticated) return ""
    const handleLogout = () => {
        if (window.confirm("Are you sure you want to log out?")){
            actions.fetchLogout()
        }
    }
    return (
        <div className="width-100pct" onClick={handleLogout}>
            {children}
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Logout);