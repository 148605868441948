import React, {useState, useEffect} from 'react';

import Modal from '../../components/Modal'
import Icon from '../../components/Icon';
import CreatePayeeCustomerForm from '../../forms/CreatePayeeCustomerForm';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import { alphabeticalSort } from '../../utils/stringUtils';

const filter = createFilterOptions();

const PayeeCustomerSelect = ({
  payeeId, 
  onChange=()=>{},
  defaultCustomer=null,
  errorText="", 
  onKeyDown=()=>{},
  payeeCustomers, 
  actions
}) => {
  //used to change the selected customer
  const [customer, setCustomer] = React.useState(defaultCustomer);
  const handleChangeCustomer = customer => {
    setCustomer(customer)
    onChange(customer)
  }
  //load all customers for this payee (once a day)
  const [payeeCustomersLoaded, setPayeeCustomersLoaded] = useState(false)
  const loadPayeeCustomers = async () => {
    const success = await actions.fetchSavePayeeCustomers(payeeId)
    if (success) setPayeeCustomersLoaded(true)
  }
  //listen to payee customers edited in the last day
  const [payeeCustomersListener, setPayeeCustomersListener] = useState(()=>()=>{})
  const listenToRecentlyEditedPayeeCustomers = async () => {
      const newPayeeCustomersListener = await actions.fetchSubscribeToPayeeCustomersEditedAfterDate(payeeId)
      setPayeeCustomersListener(() => newPayeeCustomersListener)
  }
  useEffect(() => {
    //clear the customer if the payee changes
    setCustomer(defaultCustomer)
    //load customers for the current payee
    loadPayeeCustomers()
    listenToRecentlyEditedPayeeCustomers()
    return () => {
        if (typeof payeeCustomersListener === "function") payeeCustomersListener()
    }
  }, [payeeId])
  //store the customerList to keep the reference fixed
  const [customerList, setCustomerList] = useState([])
  useEffect(() => {
    const newCustomerList = Object.values(payeeCustomers.payeeCustomersById)
                                  .filter(payeeCustomer => payeeCustomer.payeeId === payeeId)
                                  .sort((cA, cB) => alphabeticalSort(cA.name, cB.name))
    setCustomerList(newCustomerList)
  }, [payeeCustomers.lastUpdatedAt, payeeId])
  //if the default customer from the parent changes and it is not equal to the selected option
  //in the local state, then replace it with the new value from the parent
  useEffect(() => {
    if (! 
        (!defaultCustomer && !customer) ||
        (defaultCustomer && customer && defaultCustomer.id === customer.id)
      ){
        setCustomer(defaultCustomer)
      }
  }, [defaultCustomer])
  //open or close the modal with the CreateCustomerForm
  const [modalOpen, setModalOpen] = React.useState(false);
  //store the name which was typed by the user, so it can be passed to the CreateCustomerForm as the default for name
  const [defaultName, setDefaultName] = React.useState("")
  const handleCloseModal = () => {
    setDefaultName("")
    setModalOpen(false);
  };
  //triggered after a customer is successfully created
  const handleCreateCustomer = customerId => {
    const customer = payeeCustomers.payeeCustomersById[customerId]
    if (customer) handleChangeCustomer(customer)
    handleCloseModal();
  };
  //triggered when an option is chosen from the suggestion list
  const handleSelect = (event, params) => {
    //if there is no match, there will be an inputValue field containing the text typed by the user
    if (params && params.inputValue) {
      setModalOpen(true);
      setDefaultName(params.inputValue)
    } else {
      handleChangeCustomer(params);
    }
  } 
  //filter the customers based on the text typed into the input
  const filterOptions = (customerList, params) => {
    const filtered = filter(customerList, params);
    //if characters have been typed into the input, but no customer is selected
    //add the option to add a new customer
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: <button className='button secondary'>Add {params.inputValue ? `"${params.inputValue}"` : "New Customer"}</button>,
      });
    }

    return filtered;
  }
  //used to set the text in the input, after a selection is made
  const getOptionLabel = (option) => {
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.name;
  }
  return (
    <React.Fragment>
      <Autocomplete
        value={customer}
        onChange={handleSelect}
        filterOptions={filterOptions}
        options={customerList}
        getOptionLabel={getOptionLabel}
        onKeyDown={onKeyDown}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => option.inputValue ?
                                        <li {...props}>{option.name}</li>
                                        :
                                        <li {...props}><Icon icon="account-circle"/><span className='margin-left-05em'></span>{option.name}</li>                  
                      }
        sx={{ width: "100%" }}
        renderInput={(params) => <div ref={params.InputProps.ref} className={`${errorText ? "error" : ""} margin-bottom-05em`}>
                                    <label {...params.InputLabelProps} className='label'>Customer Name </label>
                                    <input {...params.inputProps} className='input' />
                                    {errorText ? <div className="errorText" >{errorText}</div>: null}
                                 </div>
                                }
      />

      <Modal
        isOpen={modalOpen}
        closeModal={handleCloseModal}
        showCloseButton={false}
        className="height-fit-content max-height-100pct display-width-400px"
        overlayClassName="flex-center padding-1pct"
        innerClassName="max-height-80vh overflow-y-auto"
        closeOnOverlayClick={true}
      > 
        <CreatePayeeCustomerForm 
          defaultValues={{name: defaultName}}
          payeeId={payeeId}
          onSuccess={handleCreateCustomer}
        />
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  payeeCustomers: state.payeeCustomers
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeCustomerSelect)