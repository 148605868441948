import { useState, useEffect } from 'react';
import { TEMPORARY_NON_LIVE_VISIBILITY } from "../constants/interface"

function useScreenRefresh(interval=TEMPORARY_NON_LIVE_VISIBILITY){
    const [screenLastUpdatedAt, setScreenLastUpdatedAt] = useState(0)
    //used to refer to the interval, so we can close it
    const [intervalId, setIntervalId] = useState(null)
    useEffect(() => {
        const newIntervalId = setInterval(() => setScreenLastUpdatedAt(Date.now()), interval)
        setIntervalId(newIntervalId)
        return () => {
            clearInterval(newIntervalId)
        }
    }, [])
    return {screenLastUpdatedAt, intervalId}
}

export default useScreenRefresh