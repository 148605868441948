import React from "react"
import ScrollToTop from '../../components/ScrollToTop'
import Loading from "../../components/Loading"
import {Outlet} from "react-router-dom"

import {connect} from "react-redux"

//this wraps the routes in the RouterProvider
function AppLayout ({system}){  

    return (
      <div>
        {
            system.loading ? 
            <Loading />
            :
            null
        }
        <ScrollToTop />
        <Outlet />
      </div>
    )
  }

const mapStateToProps = state => ({
    system: state.system,
})

export default connect(mapStateToProps)(AppLayout)