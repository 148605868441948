import React from "react";

import {useNavigate} from "react-router-dom"
import ActivityListItem from "../ActivityListItem"
import Icon from "../Icon";

import AmountSmallAfterDecimal from "../AmountSmallAfterDecimal";

import { capitalize } from "../../utils/stringUtils";
import { shortenReference } from "../../utils/interfaceUtils";

import {PAYMENT_OBJECT_TYPE_INVOICE, PAYMENT_OBJECT_TYPE_ONE_TIME_PAYMENT} from "../../constants/payment" 

const ReceiptActivityListItem = ({receipt={}}) => {
    const {reference="", reason="", totalXcd="0", payeeName="", payeeHandleId="", objectType} = receipt
    const icon = objectType === PAYMENT_OBJECT_TYPE_INVOICE ?
                 "receipt" :
                 objectType === PAYMENT_OBJECT_TYPE_ONE_TIME_PAYMENT ?
                 "monetization-on" :
                 ""
    const navigate = useNavigate()
    const goToReceipt = () => {
        
        navigate(`/${payeeHandleId}/r/${receipt.id}`)
    }
    return (
        <ActivityListItem 
            onClick={goToReceipt}
            left={<div style={{"backgroundColor": "var(--secondary)"}} className="innerIconContainer"><Icon icon={icon}/></div>}
            right={<div className="display-flex align-items-center">
                        <div className="">
                            <div className="amount h3 text-align-right">$<AmountSmallAfterDecimal amount={totalXcd}/></div>
     
                        </div>
                    </div>}
            title={<div>#{shortenReference(reference)}</div>}    
            details={
                <div>
                    <div></div>
                    {payeeName ? <div className="color-grey">@ {payeeName}</div> : null}
                </div>
            }
            reason={capitalize(reason)}
        />
    )
}

export default  ReceiptActivityListItem
