import {INVOICE_STATUS_PAID} from "../constants/invoices";

export const getInvoiceLink = (invoiceId, payeeHandleId = "") => {
    return `${window.location.origin}/${payeeHandleId}/pay/${invoiceId}`
}

export const getInvoiceReceiptLink = (receiptId, payeeHandleId = "") => {
    return `${window.location.origin}/${payeeHandleId}/r/${receiptId}`
}

export const openReceiptInfo = (receiptId, payeeHandleId = "") => {
    return window.open(getInvoiceReceiptLink(receiptId, payeeHandleId));
}

export const isFullyPaidInvoice = (invoiceStatus) => {
    return invoiceStatus === INVOICE_STATUS_PAID;
}