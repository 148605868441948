export const numberToCurrencyString = (num, options={showDoubleZeroCents: false}) => {
    if (isNaN(num)) return ""
    const {showDoubleZeroCents} = options
    let currencyString = Number(num).toFixed(2)
    if (!showDoubleZeroCents) {
        //if we are not to show the double zero cents
        const [units, cents] = currencyString.split(".")
        //and there is a double zero
        //then return only the value before the decimal
        if (cents === "00") currencyString = units
    }
    return currencyString
}