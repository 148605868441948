/**
 *  Purpose: the modifications that are carried on the One-time payment objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError, simplifyCallableErrorCode} from "../utils/errorHandlingUtils"

// STATE
// oneTimePayments:{
//   oneTimePaymentsById:{},
//   createOneTimePaymentError:null,
// }


const oneTimePaymentsReducer = (state = initialState.oneTimePayments, action) => {
    const {type, payload} = action;
    let oneTimePaymentsById = {...state.oneTimePaymentsById}
    switch (type) {
        case types.SAVE_ONE_TIME_PAYMENTS: {
            if (typeof payload.oneTimePayments !== "object") {
                logError(`oneTimePaymentsReducer > SAVE_ONE_TIME_PAYMENTS: oneTimePayments payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.oneTimePayments.forEach(oneTimePayment => {
                oneTimePaymentsById[oneTimePayment.id] = oneTimePayment
            });
            return {
                ...state,
                oneTimePaymentsById,
            }
        }

        case types.CREATE_ONE_TIME_PAYMENT: {
            if (typeof payload.oneTimePayment !== "object") {
                logError(`oneTimePaymentsReducer > CREATE_ONE_TIME_PAYMENT: oneTimePayment payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            oneTimePaymentsById[payload.oneTimePayment.id] = payload.oneTimePayment
            return {
                ...state,
                oneTimePaymentsById
            }
        }

        case types.CREATE_ONE_TIME_PAYMENT_ERROR: {
            const {error} = payload;

            let errorMessage = "Something went wrong.\nPlease, check your internet connection and try again."
            if (typeof error !== "object") {
                return {
                    ...state,
                    createOneTimePaymentError: errorMessage
                }
            }

            const simplifiedError = simplifyCallableErrorCode(error);

            // An unlikely scenario when auth was not verified. In the UI, the auth flow always happens first, so should not be the case.
            if (simplifiedError.code === 'unauthenticated') {
                errorMessage = "Invalid authorization credentials.\nPlease clear your cache or try using a different phone number."
            }
            // An unlikely scenario where OTP data is incorrect. UI should validate everything before the data is sent to a callable
            if (simplifiedError.code === 'invalid-argument') {
                errorMessage = "Invalid payment data.\nPlease, make sure the data you entered is correct or contact the Shopdm Pay team."
            }
            // An unlikely scenario, when the payeeId we have received was not found in the DB. Request is made through our UI and we fetch payeeId by payeeName from DB.
            if (simplifiedError.code === 'not-found') {
                errorMessage = "The requested merchant was not found.\nThis error has been logged and our tech team is investigating it."
            }
            // Possible scenario, where payee is not active. Likeliness depends on whether we lock OTP UI for deactivated payees.
            if (simplifiedError.code === 'failed-precondition') {
                errorMessage = "The merchant you are trying to pay is no longer active.\nPlease ask the merchant to contact the Shopdm Pay team."
            }
            // Payee transaction or monthly limits exceeded. A very likely scenario
            if (simplifiedError.code === 'resource-exhausted') {
                errorMessage = "Merchant limit exceeded.\nPlease ask the merchant to contact the Shopdm Pay team to review their limits."
            }

            return {
                ...state,
                createOneTimePaymentError: errorMessage
            }
        }

        case types.RESET_ONE_TIME_PAYMENT_CREATE_ERROR: {
            return {
                ...state,
                createOneTimePaymentError: null
            }
        }
        default:
            return state
    }
}

export default oneTimePaymentsReducer