import React from "react";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import PayeeProduct from "../../components/PayeeProduct";
import IncrementInput from "../../components/IncrementInput";
import currency from 'currency.js'
import { Skeleton } from "@mui/material";

class PayeeProductTotalCalculator extends React.Component {

    constructor(props) {
        super(props)
        const {payeeProducts={}, payeeProductId=""} = this.props
        this.state = {
            productLoaded: Boolean(payeeProducts.payeeProductsById[payeeProductId]),
            payeeProductListener: () => {},
            selectedVariantId: "",
            selectedQuantity: 1,
        }
    }

    static defaultProps = {
        onChange: ({totalXcd, selectedQuantity, selectionDesc}) => {}
    }

    componentDidMount = () => {
        this.loadPayeeProduct(this.props.payeeProductId)
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {payeeProductId} = this.props
        if (prevProps.payeeProductId !== payeeProductId){
            this.loadPayeeProduct(payeeProductId)
        }
        
        //ensure that changes to the calculated values are pushed to the parent
        const {onChange, payeeProducts} = this.props
        const {selectedQuantity, selectedVariantId} = this.state
        if (
            prevState.selectedVariantId !== selectedVariantId ||
            prevState.selectedQuantity !== selectedQuantity
        ) {
            const payeeProduct = payeeProducts.payeeProductsById[payeeProductId] || {}
            const {price, name, imageUrl} = this.getProductVariantValues(payeeProduct, selectedVariantId)
            const totalXcd = currency(price).multiply(selectedQuantity).value
            const {quantityInStock, hasLimitedStock} = this.getPayeeProductVariantStock(payeeProduct, selectedVariantId)
            const selectedItem = selectedVariantId ?
                                {
                                    id: selectedVariantId,
                                    payeeProductId: payeeProduct.id ? payeeProduct.id : "",
                                    productType: payeeProduct.productType ? payeeProduct.productType : "",
                                    price,
                                    name,
                                    imageUrl,
                                    quantity: selectedQuantity,
                                    quantityInStock,
                                    hasLimitedStock
                                }
                                :
                                null
            const selectionDesc = `${selectedQuantity ? `${selectedQuantity} x `: ""}${name}`
            onChange({
                totalXcd, 
                selectedQuantity, 
                selectionDesc,
                selectedItem
            })
        }
    }

    loadPayeeProduct = async (payeeProductId="") => {
        const {actions} = this.props
        this.state.payeeProductListener()
        let payeeProductListener = () => {}
        if (payeeProductId) {
            this.setState({productLoaded: false})
            payeeProductListener = await actions.fetchSubscribeToPayeeProduct(
                payeeProductId, 
                payeeProduct => this.handlePayeeProductLoaded(payeeProduct)
            )
        }
        this.setState({payeeProductListener})
    }

    handlePayeeProductLoaded = (payeeProduct={}) => {
        //get the default values from the product, and update state with auto calculated totals
        const {payeeId} = this.props
        const {defaultVariantId=""} = payeeProduct
        let {selectedQuantity=0, selectedVariantId=""} = this.state
        selectedVariantId = selectedVariantId ? selectedVariantId : defaultVariantId
        if (payeeId === payeeProduct.payeeId){
            const {quantityInStock, minimumOrderQty, hasLimitedStock} = this.getPayeeProductVariantStock(payeeProduct, selectedVariantId)
            //a variant is either always in stock or in stock when its quantity is higher than its minimum order quantity
            const inStock = !hasLimitedStock || quantityInStock >= minimumOrderQty
            if (inStock) {
                //if it is in stock, set the selected quantity to 
                //the existing selected quantity, if it is above the min and below the max
                //otherwise, set it to the min 
                selectedQuantity = selectedQuantity >= minimumOrderQty && 
                                   selectedQuantity <= quantityInStock ? 
                                   selectedQuantity : 
                                   minimumOrderQty
            } else selectedQuantity = 0
        } 
        this.setState({
            productLoaded: true,
            selectedVariantId,
            selectedQuantity,
        })
    }

    getPayeeProductVariantStock = (payeeProduct, variantId="") => {
        const stock = {quantityInStock: 0, minimumOrderQty: 1, hasLimitedStock: false}
        //if there is no product, return the default stock
        if (!payeeProduct) return stock
        //use the variant id provided or fall back to the product's default variant id
        const {defaultVariantId="", variantsById={}} = payeeProduct
        variantId = variantId ? variantId : defaultVariantId
        const variant = variantsById[variantId]
        if (!variant) return stock
        else {
            //if the variant exists, get it's values and set those to the stock object
            const {quantityInStock=0, minimumOrderQty=1, hasLimitedStock=false} = variant
            return {...stock, quantityInStock, minimumOrderQty, hasLimitedStock}
        }
    }

    getProductVariantValues = (payeeProduct, variantId="") => {
        const values = {price:0, imageUrl: "", name:""}
        //if there is no product, return the default values
        if (!payeeProduct) return values
        //set the default values to those of the general product
        values.price = payeeProduct.price
        values.name = payeeProduct.name
        values.imageUrl = payeeProduct.imageUrl
        //use the variant id provided or fall back to the product's default variant id
        const {defaultVariantId="", variantsById={}} = payeeProduct
        variantId = variantId ? variantId : defaultVariantId
        const variant = variantsById[variantId]
        if (!variant) return values
        else {
            //if the variant exists, get it's values and set those to the values object
            const {price=0, images=[], variantDetails=""} = variant
            const name = variantDetails ? `${values.name} - ${variantDetails}` : values.name
            const imageUrl = images && images.length > 0 ? images[0].med : payeeProduct.imageUrl
            return {...values, price, name, imageUrl}
        }
    }

    componentWillUnmount = () => {
        this.state.payeeProductListener()
    }

    handleChangeSelectedQuantity = selectedQuantity => {
        this.setState({selectedQuantity})
    }
    
    render(){
        const {payeeProductId="", payeeProducts={}, payeeId=""} = this.props
        //if there is not a product id return nothing
        if (!payeeProductId) return "" 
        const {productLoaded, selectedVariantId} = this.state
        const {payeeProductsById={}} = payeeProducts
        const payeeProduct = payeeProductsById[payeeProductId] || {}
        //if the product is loaded, but it does not exist then the id is invalid
        if (
            productLoaded && 
            (
                !payeeProduct ||
                !payeeProduct.id ||
                payeeProduct.payeeId !== payeeId
            ) 
        )return "Not Found" //TODO handle not found, perhaps at a higher level
        const {name, price, imageUrl} = this.getProductVariantValues(payeeProduct, selectedVariantId)
        const {quantityInStock, minimumOrderQty, hasLimitedStock} = this.getPayeeProductVariantStock(payeeProduct, selectedVariantId)
        const {selectedQuantity} = this.state
        const totalXcd = currency(price).multiply(selectedQuantity).value
        return (
            <div className="display-flex flex-direction-column align-items-center">  
                <PayeeProduct 
                    imageUrl={imageUrl}
                    name={name}
                    selectedQuantity={selectedQuantity}
                    price={price}
                    totalXcd={totalXcd}
                    loaded={productLoaded}
                />
                {/* <div>(selectedQuantity: {selectedQuantity} selectedVariantId: {selectedVariantId})</div> */}
                <div className="margin-top-05em">
                    {
                        productLoaded ?
                        <div>
                            {
                                quantityInStock >= minimumOrderQty ?
                                <IncrementInput 
                                    value={selectedQuantity}
                                    onChange={this.handleChangeSelectedQuantity}
                                    min={minimumOrderQty}
                                    max={hasLimitedStock ? quantityInStock : undefined}
                                />
                                :
                                <div className="badge error">Sold Out</div>
                            }
                        </div>
                    :
                        <Skeleton variant="rectangular" width={125} height={40} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeProductTotalCalculator)