/**
 *  Purpose: the modifications that are carried on the Receipt objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // receipts:{
 //   receiptsById:{},
 //   lastReceiptEmail: ""
 // }
 
 
 const receiptsReducer = (state = initialState.receipts, action) => {
     const {type, payload} = action;
     let receiptsById = {...state.receiptsById}
     switch (type){
         case types.SAVE_RECEIPTS: {
             if(typeof payload.receipts !== "object") {
                 logError(`receiptsReducer > SAVE_RECEIPTS: receipts payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.receipts.forEach(receipt => {
                receiptsById[receipt.id] = receipt
             });
             return {
                 ...state,
                 receiptsById,
             }
         }

         case types.SET_LAST_RECEIPT_EMAIL: {
            if(typeof payload.lastReceiptEmail !== "string") {
                logError(`receiptsReducer > SET_LAST_RECEIPT_EMAIL: lastReceiptEmail payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                lastReceiptEmail: payload.lastReceiptEmail
            }
        }

         default: return state
     }
 }
 
 export default receiptsReducer