export const copyToClipboard = str => {
    /**
     * Source: https://www.30secondsofcode.org/blog/s/copy-text-to-clipboard-with-javascript
     */
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const shareNativePopup = async (link, text="", title="Shopdm Pay") => {
    try {
        await navigator.share({
            title,
            text,
            url: link,
            href: link
        })
    } catch (err) {
        console.error("Native share failed: ", err.message)
        if (err.message.toLowerCase() === "share canceled") return
        alert(`Sorry, we were unable to share the link ${err.message}`)
    } 
}

export const shareViaEmail = (props = {}) => {
    const {
        email="",
        subject="",
        body=""
    } = props
    const a = document.createElement('a');
    const subjectEncoded = encodeURIComponent(subject);
    const bodyEncoded = encodeURIComponent(body)
    a.href = `mailto:${email}?Subject=${subjectEncoded}&Body=${bodyEncoded}`;
    a.target = "_blank"
    document.body.appendChild(a);
    a.click()
    document.body.removeChild(a);
};

export const shareViaWhatsapp = link => {
    window.open(`https://web.whatsapp.com/send?text=${link}`)
}