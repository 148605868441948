/**
 *  Purpose: the modifications that are carried on the Payee Customer objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 // STATE
 // payeeCustomers:{
 //  payeeCustomersById:{},
 //  lastLoadedByPayeeId: {}
 // }
 
 
 const payeeCustomersReducer = (state = initialState.payeeCustomers, action) => {
     const {type, payload} = action;
     let payeeCustomersById = {...state.payeeCustomersById}
     let lastLoadedByPayeeId = {...state.lastLoadedByPayeeId}
     switch (type){
         case types.SAVE_PAYEE_CUSTOMERS: {
            if(typeof payload.payeeCustomers !== "object") {
                logError(`payeeCustomersReducer > SAVE_PAYEE_CUSTOMERS: payeeCustomers payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.payeeId !== "string") {
                logError(`payeeCustomersReducer > SAVE_PAYEE_CUSTOMERS: payeeId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            payload.payeeCustomers.forEach(payeeCustomer => {
                payeeCustomersById[payeeCustomer.id] = payeeCustomer                                 
            })
            //track the last time customers were loaded for this payee
            if (payload.lastLoadedAt){
                lastLoadedByPayeeId[payload.payeeId] = payload.lastLoadedAt
            }
            return {
                ...state,
                payeeCustomersById,
                lastLoadedByPayeeId,
                lastUpdatedAt: Date.now()
            }
        }

        case types.CREATE_PAYEE_CUSTOMER: {
            if(typeof payload.payeeCustomer !== "object") {
                logError(`payeeCustomersReducer > CREATE_PAYEE_CUSTOMER: payeeCustomer payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payeeCustomersById[payload.payeeCustomer.id] = payload.payeeCustomer
            return {
                ...state,
                payeeCustomersById,
                lastUpdatedAt: Date.now()
            }
        }
 
        case types.LOGOUT: {
            return initialState.payeeCustomers
        }

         default: return state
     }
 }
 
 export default payeeCustomersReducer