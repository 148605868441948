import React, {useState, useEffect} from "react"
import Icon from "../../../components/Icon"
import DateRangePicker from "../../../components/DateRangePicker";

import InvoiceTable from "../../../containers/InvoiceTable"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';

import { DAY_IN_MILLISECONDS } from "../../../constants/datetime";
import {getTimestampForStartOfDay} from "../../../utils/datetimeUtils"

const Invoices = ({invoices, payees, actions}) => {
    const {payeeId} = useOutletContext()

     /**subscribe to a listener for the payee's invoices **/
    //from 30 days ago
    const [fromDate, setFromDate] = useState(getTimestampForStartOfDay(Date.now() - (DAY_IN_MILLISECONDS * 30)))
    //to the end of today
    const [toDate, setToDate] = useState(getTimestampForStartOfDay(Date.now()) + DAY_IN_MILLISECONDS - 1)
    //update the toDate and fromDate whne the DateRangePicker is toggled
    const handleChangeDateRange = dateRange => {
        const {fromDate, toDate} = dateRange
        setFromDate(fromDate)
        setToDate(toDate)
    }
    const [invoicesListener, setInvoicesListener] = useState(() => () => {})
    //listen to any invoices for this payee made in the specified date range
    const listenToInvoices = async () => {
        const newInvoicesListener = await actions.fetchSubscribeToPayeeInvoicesInDateRange(
            payeeId, 
            fromDate,
            toDate
        )
        setInvoicesListener(() => newInvoicesListener)
    }
    useEffect(() => {
        listenToInvoices()
        return () => {
            if (typeof invoicesListener === "function") invoicesListener()
        }
    }, [payeeId, fromDate, toDate])
    const invoicesInDateRange = Object.values(invoices.invoicesById)
                                           .filter(i => i.payeeId === payeeId &&
                                                        i.createdAt >= fromDate && i.createdAt <= toDate
                                                  )
    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    return (
        <div>
            <Helmet>
                <title>Invoices - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="receipt"/> Invoices</div>
            <div className="margin-top-1em">
                <DateRangePicker 
                    dateRange={{fromDate, toDate}}
                    onChange={handleChangeDateRange}   
                />
                <InvoiceTable 
                    invoicesArray={invoicesInDateRange}
                    title="Invoices"
                    payeeHandleId={payee.handleId}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    invoices: state.invoices,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoices)