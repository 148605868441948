import React from "react"

const CreateAccount = () => {
    return (
        <div>
            Create Account
        </div>
    )
}

export default CreateAccount