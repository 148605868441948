import React from 'react';
import ReactModal from 'react-modal';
import CloseButton from "../CloseButton";
import {CLOSE_BUTTON_THEME_DARK} from "../../constants/interaction"

ReactModal.setAppElement("body")

class Modal extends React.Component{
    /**
     * Purpose: a configurable modal used across the app.
     */

    static defaultProps = {
        showCloseButton: true,
        closeButtonTheme: CLOSE_BUTTON_THEME_DARK,
        closeOnOverlayClick: false,
        customStyles: {},
        className: "",
        overlayClassName: "",
        innerClassName: "",
        title: ""
    }

    render(){
        const {customStyles, className, innerClassName, overlayClassName, closeOnOverlayClick, showCloseButton, closeButtonTheme, title, isOpen} = this.props
        return(
            <ReactModal
                isOpen={isOpen}
                contentLabel={this.props.contentLabel}
                className={['modalContent', className].join(" ")}
                overlayClassName={['modalOverlay', overlayClassName].join(" ")}
                shouldCloseOnOverlayClick={closeOnOverlayClick}
                onRequestClose={this.props.closeModal}
                style={customStyles}
            >
                <style dangerouslySetInnerHTML={{"__html": `
                    html { overscroll-behavior-y: none; }
                `}} />
                <div className="modalTitleContainer">
                    <div className="title space-left-05em">{title}</div>
                    <div className={'modalCloseButtonContainer'}>
                        {
                            showCloseButton ? 
                                <CloseButton  onClick={this.props.closeModal} theme={closeButtonTheme}/> 
                            : 
                                null
                        }
                    </div>
                </div>
                
                <div className={['modalInnerContainer', innerClassName].join(" ")}>
                
                {this.props.children}
                </div>
            </ReactModal>
        )
    }
}

export default Modal;