import React from "react"
import {Outlet, Link} from "react-router-dom"
import Navigation from "../../components/Navigation"
import Icon from "../../components/Icon"
import styles from "./PayLayout.module.css"

function PayLayout ({}) {
    return (
        <div className={styles.container}>
            <Navigation backgroundColor="var(--white)">
                <ul className="navMain">
                    <li>
                        <button className="button secondary"><Link to="/" className=" icon icon-left flex-center"><Icon icon="arrow-back"/> Back To Home</Link></button>
                    </li>
                </ul>
            </Navigation>
            <div className="main padding-1em">
                <Outlet />
            </div>
        </div>
    )
}

  export default PayLayout