const contact = {
    maintenanceEmail: "pay@shopdm.store",
    website: "https://pay.shopdm.store",
    maintenancePhone: "+17676128202",
    operationsPhone: "+17676120435",
    partnersPhone: "+17672453052",
    companyAddress: {
        line1: "Top Floor, 20 Bath Road",
        city: "Roseau",
        country: "Dominica",
    }
}

export const getFullCompanyAddress = () => `${contact.companyAddress.line1}, ${contact.companyAddress.city}, ${contact.companyAddress.country}`

export default contact