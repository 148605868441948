import React, {useState} from "react"
import Icon from "../Icon"
import styles from "./LinkDisplay.module.css"
import { copyToClipboard } from "../../utils/interactionUtils"
import PopoverWithArrow from "../PopoverWithArrow";

const LinkDisplay = ({link="", buttonText="Open", title=""}) => {
    //control popover
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickInput = e => {
        setAnchorEl(e.currentTarget);
        copyToClipboard(link)
        setTimeout(() => setAnchorEl(null), 1000)
    }
    const handleClickButton = () => {
       if (link) window.open(link, "_blank")
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div >
            {title ? title : null}
            <div className={styles.innerContainer}>
                <input readOnly={true} value={link} onClick={handleClickInput}/>
                <button className="button icon icon-right secondary" onClick={handleClickButton}>{buttonText}<Icon icon="open-in-new"/></button>
            </div>
            <PopoverWithArrow
                id={id}
                open={open} 
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <div className="padding-05em font-size-14px icon-left display-flex"><Icon icon="content-copy"/> copied!</div> 
            </PopoverWithArrow>
        </div>
    )
}

export default LinkDisplay