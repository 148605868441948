import React from "react"

import MultiSearchField from "../MultiSearchField"
import {timestampsAreOnSameDay, timestampToDateString} from "../../utils/datetimeUtils"
import {stringBContainsStringA} from "../../utils/stringUtils"

import styles from "./ListByDate.module.css"

class ListByDate extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            searchTerm: "",
            searchBy: props.searchFields && props.searchFields.length > 0 ? props.searchFields[0] : ""
        }
    }

    static defaultProps = {
        itemList: [],
        title: "List",
        searchFields: [],
        searchFieldToLabelMap: {},
        dateField: "createdAt", //the field used to organize the items by date
        renderItem: () => <div></div>,
        getKeyFromItem: item => item && item.id ? item.id : "",
        getIdFromItem: item => item && item.id ? item.id : "",
        placeholder: ""
    }

    filterBySearch = itemList => {
        const {searchTerm, searchBy} = this.state
        if (!searchTerm.trim()) return itemList
        if (!searchBy) {
            console.warn("There is no search by")
            return itemList
        }
        return itemList.filter(data => {
            return stringBContainsStringA(searchTerm, String(data[searchBy]))
        })
    }

    handleChangeSearchTerm = searchTerm => this.setState({
        searchTerm,
        currentPageIndex: 0
    })

    render(){
        const {itemList, title, dateField, renderItem, getKeyFromItem, getIdFromItem, placeholder, searchFields, searchFieldToLabelMap} = this.props
        const {searchTerm, searchBy} = this.state
        const filteredItemList = searchBy ? this.filterBySearch(itemList) : itemList
        filteredItemList.sort((iA, iB) => iB[dateField] - iA[dateField])
        let currentDay = Date.now()
        const today = currentDay
        let firstItemToday = -1 //used to show "today" if there were any transactions today
        return (
            <div>
                <div className="h3">{title}</div>
                {
                    searchBy ?
                            <MultiSearchField
                                searchTerm={searchTerm}
                                searchFields={searchFields.map(field => ({key: field, label: searchFieldToLabelMap[field] ? searchFieldToLabelMap[field] : field}))}
                                searchBy={searchBy}
                                onChangeSearchTerm={this.handleChangeSearchTerm}
                                onChangeSearchBy={searchBy => this.setState({searchBy})}
                            />
                            :
                            null
                }
                {itemList.length === 0 && placeholder? <div>{placeholder}</div> : null}
                <div className={styles.list}>
                    {
                        filteredItemList.map(
                            (item, i) => {
                                const date = item[dateField]
                                let displayDate = false
            
                                if (!timestampsAreOnSameDay(currentDay, date)){
                                    displayDate = true
                                    currentDay = date
                                } else if (currentDay === today){
                                    if (firstItemToday < 0) firstItemToday = i 
                                }
                                return (
                                    <div key={getKeyFromItem(item)} className={styles.listItem}>
                                        {
                                            firstItemToday === i ?
                                            <div className="h4 padding-1em text-transform-uppercase">Today</div>
                                            :
                                            displayDate ? 
                                            <div className="h4 padding-1em text-transform-uppercase">{timestampToDateString(currentDay, "EEEE, dd MMMM yy")}</div>
                                            :
                                            null
                                        }
                                        {renderItem(item)}
                                    </div>
                                )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default ListByDate