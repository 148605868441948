import React, {useState,} from "react"

import CustomerForm from "../PayeeCustomerForm"
import {v4 as uuid4} from 'uuid'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const CreatePayeeCustomerForm = ({
    payeeId,
    title="Add New Customer",
    buttonText="Create Customer",
    defaultValues={},
    postSubmit=()=>{}, 
    onCancel=()=>{}, 
    onSuccess=()=>{},
    actions,

}) => {
    const[payeeCustomerId] = useState(uuid4())
    const handleSubmit = async (
        name,
        phoneNumber,
        address,
        email,
        handleSubmitSuccess, 
        handleSubmitError
    ) => {
        return await actions.fetchCreatePayeeCustomer(
            payeeCustomerId,
            payeeId,
            name,
            phoneNumber,
            address,
            email,
            handleSubmitSuccess, 
            handleSubmitError
        )
    }
    return (
        <CustomerForm 
            title={title}
            defaultValues={defaultValues}
            buttonText={buttonText}
            onSubmit={handleSubmit}
            postSubmit={postSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            actions={actions}
            payeeId={payeeId}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  
export default connect(null, mapDispatchToProps)(CreatePayeeCustomerForm)