import React, {useState, useEffect} from "react"
import Icon from "../../../components/Icon"

import CustomerTable from "../../../containers/CustomerTable"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';


import { alphabeticalSort } from '../../../utils/stringUtils';

const Customers = ({payeeCustomers, payees, actions}) => {
    const {payeeId} = useOutletContext()

   //load all customers for this payee (once a day)
    const [payeeCustomersLoaded, setPayeeCustomersLoaded] = useState(false)
    const loadPayeeCustomers = async () => {
        const success = await actions.fetchSavePayeeCustomers(payeeId)
        if (success) setPayeeCustomersLoaded(true)
    }
    //listen to payee customers edited in the last day
    const [payeeCustomersListener, setPayeeCustomersListener] = useState(()=>()=>{})
    const listenToRecentlyEditedPayeeCustomers = async () => {
        const newPayeeCustomersListener = await actions.fetchSubscribeToPayeeCustomersEditedAfterDate(payeeId)
        setPayeeCustomersListener(() => newPayeeCustomersListener)
    }
    useEffect(() => {
        //load customers for the current payee
        loadPayeeCustomers()
        listenToRecentlyEditedPayeeCustomers()
        return () => {
            if (typeof payeeCustomersListener === "function") payeeCustomersListener()
        }
    }, [payeeId])
    //store the customerList to keep the reference fixed
    const [customerList, setCustomerList] = useState([])
    useEffect(() => {
        const newCustomerList = Object.values(payeeCustomers.payeeCustomersById)
                                    .filter(payeeCustomer => payeeCustomer.payeeId === payeeId)
                                    .sort((cA, cB) => alphabeticalSort(cA.name, cB.name))
        setCustomerList(newCustomerList)
    }, [payeeCustomers.lastUpdatedAt, payeeId])
    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    return (
        <div>
            <Helmet>
                <title>Customers - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="account-circle"/> Customers</div>
            <div className="margin-top-1em">
                <CustomerTable 
                    customersArray={customerList}
                    title="Customers"
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeCustomers: state.payeeCustomers,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Customers)