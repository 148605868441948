import {createTransform} from "redux-persist";

export const payersSliceTransform = createTransform(
    // Transforming state coming from redux on its way to being serialized and persisted
    (inboundState, key) => {
        // Making a shallow copy to avoid mutating the original state
        const stateCopy = {...inboundState};

        // Removing property we don't want to persist
        delete stateCopy.activeAuthenticationData;

        return stateCopy;
    },
    // Transforming state coming from storage, on its way to being rehydrated into redux
    (outboundState, key) => {
        // Returning the state as-is
        return outboundState;
    },
    {whitelist: ['payers']}
);