import React, {useState, useEffect} from "react"

import Icon from "../../../components/Icon";
import AmountSmallAfterDecimal from "../../../components/AmountSmallAfterDecimal"
import DateRangePicker from "../../../components/DateRangePicker";
import Modal from "../../../components/Modal";

import DisbursementTable from "../../../containers/DisbursementTable"
import PayeeActivityDateList from "../../../containers/PayeeActivityDateList";

import useBalance from "../../../hooks/useBalance";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';

import { DAY_IN_MILLISECONDS } from "../../../constants/datetime";
import {getTimestampForStartOfDay} from "../../../utils/datetimeUtils"

const Payouts = ({payouts, disbursements, payeeActivity, actions}) => {
    const {payeeId} = useOutletContext()
    /** calculate the current balance */
    const balance = useBalance({payeeId, payouts, actions})
    
    /** open a modal that displays the payee activity objects that add up to the current balance **/
    const [transactionModalOpen, setTransactionModalOpen] = useState(false)
    //load all of the payee activity transactions that have not yet been disbursed 
    const loadPayeeActivityNotYetDisbursed = async () => {
        actions.toggleLoading(true)
        const success = await actions.fetchSavePayeeActivityForDisbursement(
            null, //use null to indicate that there is no disbursementId
            payeeId
        )
        actions.toggleLoading(false)
        if (!success) alert(`ERROR: failed to load transactions, check your internet connection and try again`)
    }
    useEffect(() => {
        //load the payee activity objects/transactions associated with this disbursement
        if (transactionModalOpen){
            loadPayeeActivityNotYetDisbursed()
        }
    }, [transactionModalOpen])
    const payeeActivityList = Object.values(payeeActivity.payeeActivityById)
                                    .filter(p => p.payeeId === payeeId && p.isPayment && p.disbursementId === null )
    /**subscribe to a listener for the payee's disbursements **/
    //from 30 days ago
    const [fromDate, setFromDate] = useState(getTimestampForStartOfDay(Date.now() - (DAY_IN_MILLISECONDS * 30)))
    //to the end of today
    const [toDate, setToDate] = useState(getTimestampForStartOfDay(Date.now()) + DAY_IN_MILLISECONDS - 1)
    //update the toDate and fromDate whne the DateRangePicker is toggled
    const handleChangeDateRange = dateRange => {
        const {fromDate, toDate} = dateRange
        setFromDate(fromDate)
        setToDate(toDate)
    }
    const [disbursementsListener, setDisbursementsListener] = useState(() => () => {})
    //listen to any disbursements for this payee made in the specified date range
    const listenToDisbursements = async () => {
        const newDisbursementsListener = await actions.fetchSubscribeToPayeeDisbursementsInDateRange(
            payeeId, 
            fromDate,
            toDate
        )
        setDisbursementsListener(() => newDisbursementsListener)
    }
    useEffect(() => {
        listenToDisbursements()
        return () => {
            if (typeof disbursementsListener === "function")disbursementsListener()
        }
    }, [payeeId, fromDate, toDate])
    const disbursementsInDateRange = Object.values(disbursements.disbursementsById)
                                           .filter(d => d.payeeId === payeeId &&
                                                        d.createdAt >= fromDate && d.createdAt <= toDate
                                                  )
    return (
        <div>
            <Helmet>
                <title>Payouts - Shopdm Pay </title>
            </Helmet>
            <div>  
                <div className="h1 icon-left"><Icon icon="payment"/> Next Payout</div>
                <div className="amount big">EC $<AmountSmallAfterDecimal amount={Number(balance).toFixed(2)}/></div>
                <div><button className="button secondary" onClick={() => setTransactionModalOpen(true)}>See Transactions</button></div>
                <div className="font-size-14px">Balances are paid out to your bank account on the 1st, 8th, 15th and 22nd of every month</div>
            </div>
            <div className="margin-top-1em">
                <DateRangePicker 
                    dateRange={{fromDate, toDate}}
                    onChange={handleChangeDateRange}   
                />
                <DisbursementTable 
                    disbursementsArray={disbursementsInDateRange}
                    title="Previous Payouts"
                    payeeId={payeeId}
                />
            </div>
            <Modal
                isOpen={transactionModalOpen}
                closeModal={() => setTransactionModalOpen(false)}
                showCloseButton={false}
                className="height-fit-content max-height-100pct"
                overlayClassName="flex-center padding-1pct"
                innerClassName="max-height-80vh overflow-y-auto"
                closeOnOverlayClick={true}
            > 
                <div className="h1 display-width-400px">Next Payout</div>
                <div>Total to pay: <span className="font-weight-600">EC$<AmountSmallAfterDecimal amount={balance} /></span></div>
                <PayeeActivityDateList 
                    payeeActivityList={payeeActivityList} 
                />
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    payouts: state.payouts,
    disbursements: state.disbursements,
    payeeActivity: state.payeeActivity
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Payouts)