import React, {useState, useEffect} from "react"

import withQRScanModal from "../../hocs/withQRScanModal"
import { getInvoiceLink } from "../../utils/invoiceUtils"
import InvoiceForm from "../../forms/InvoiceForm"

import {INVOICE_STATUS_CANCELLED} from "../../constants/invoices"
import { PAYEE_ERROR_TYPE_MONTHLY_LIMIT_EXCEEDED} from "../../constants/payees";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const InvoiceFormWithQRScanModal = withQRScanModal(({setQRScanLink, payeeId, payeeHandleId, paymentRequestMessage, transactionLimitXcd,invoices, actions}) => {
    const [invoiceId, setInvoiceId] = useState("")
    //when the invoice is created open the QR scan link modal
    const handleSuccess = newInvoiceId => {
        setInvoiceId(newInvoiceId)
        setQRScanLink(getInvoiceLink(newInvoiceId, payeeHandleId))
    }
    //listen to the invoice anytime the invoiceId changes
    const [invoiceListener, setInvoiceListener] = useState(()=>()=>{})
    const listenToInvoice = async () => {
        const newInvoiceListener = await actions.fetchSubscribeToInvoice(invoiceId)
        setInvoiceListener(() => newInvoiceListener)
    }
    useEffect(() => {
        if (invoiceId) listenToInvoice()
        return () => {
            if (typeof invoiceListener === "function") invoiceListener()
        }
    }, [invoiceId])
    const invoice = invoices.invoicesById[invoiceId]
    const invoiceCancelled = invoice && invoice.closedAt && invoice.currentStatus === INVOICE_STATUS_CANCELLED
    useEffect(() => {
        if (invoiceCancelled){
            //close the scan link if it is open
            setQRScanLink("")
            if (invoice.errorType === PAYEE_ERROR_TYPE_MONTHLY_LIMIT_EXCEEDED) {
                const {errorInfo={}} = invoice
                alert(`Error: This invoice for $${invoice.totalXcd} will push you over your monthly earning limit of $${errorInfo.monthlyLimitXcd}. Make a new invoice for less or contact the Shopdm Pay team to have your limits changed.`)
            }
        }
    }, [invoiceCancelled])
    return <InvoiceForm 
                payeeId={payeeId} 
                payeeHandleId={payeeHandleId}
                paymentRequestMessage={paymentRequestMessage}
                transactionLimitXcd={transactionLimitXcd}
                onSuccess={handleSuccess}
            />
})

const mapStateToProps = state => ({
    invoices: state.invoices,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceFormWithQRScanModal)