/**
 *  Purpose: persistence of previously authenticated payers with unique phone number in the scope of OneTimePayment process
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"

//STATE
// payers: {
//     payersById: {},
//     phoneNumbers: [],
//     payerIdsByPhoneNumber: {},
//     activeAuthenticationData: null,
// }

const payersReducer = (state = initialState.payers, action) => {
    const {type, payload} = action;
    let phoneNumbers = [...state.phoneNumbers]
    let payerIdsByPhoneNumber = {...state.payerIdsByPhoneNumber}
    let payersById = {...state.payersById}

    switch (type) {
        case types.SAVE_PAYER: {
            if(typeof payload.payerData !== "object") {
                logError(`payersReducer > SAVE_PAYER: payerData payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(!payload.payerData.id) {
                logError(`payersReducer > SAVE_PAYER: payerData has no id ${JSON.stringify(payload)}`)
                return state
            }
            if(!payload.payerData.phoneNumber) {
                logError(`payersReducer > SAVE_PAYER: payerData has no phone number ${JSON.stringify(payload)}`)
                return state
            }
            const {payerData} = payload;
            const {phoneNumber} = payerData;
            //NOTE: important to combine previous and existing payer data since
            //after confirmSMS, the token will be provided, but all future writes
            //will not have the token, since we remove it before sending the payer data to the server
            //when creating One Time Payments
            payersById[payerData.id] = {
                ...payersById[payerData.id],
                ...payerData
            }
            if (!phoneNumbers.includes(phoneNumber)) {
                phoneNumbers.push(phoneNumber);
            }
            return {
                ...state,
                phoneNumbers: phoneNumbers.includes(phoneNumber)
                    // Bringing the latest phoneNumber to the end of the list,
                    // for it to be the one to be rehydrated on the next load
                    ? [...phoneNumbers.filter(num => num !== phoneNumber), phoneNumber]
                    : [...phoneNumbers, phoneNumber],
                payerIdsByPhoneNumber: {
                    ...payerIdsByPhoneNumber,
                    [phoneNumber]: payerData.id
                },
                payersById,
            }
        }
        case types.START_PAYER_AUTHENTICATION: {
            const {payerAuthenticationData} = payload;

            return {
                ...state,
                activeAuthenticationData: payerAuthenticationData
            }
        }
        case types.CLEAR_PAYER_AUTHENTICATION: {
            return {
                ...state,
                activeAuthenticationData: null
            }
        }

        default:
            return state
    }
}

export default payersReducer