import {applyMiddleware, createStore} from 'redux'
import rootReducer from '../reducers'
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {payersSliceTransform} from "./transforms";

const empty = () => {
    return next => action => {
        return next(action)
    }
}

const logger = process.env.NODE_ENV === 'production' ? empty : createLogger()
const middleware = applyMiddleware(thunk, logger)

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    timeout: null,
    whitelist: ['user', 'system', 'device', 'payees', 'receipts', 'payers'],
    transforms: [payersSliceTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    middleware
)

export const persistor = persistStore(store)

export default function configureStore() {
    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    if (module.hot && (!(process.env.NODE_ENV === 'production'))) {
        module.hot.accept('../reducers', () => store.replaceReducer(require('../reducers')));
    }
    return store;
}