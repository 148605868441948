import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, onSnapshot, getDocs} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_PAYEE_ACTIVITY = 'SAVE_PAYEE_ACITVITY'

export const savePayeeActivity = payeeActivity => {
    return {
        type: SAVE_PAYEE_ACTIVITY,
        payload: {
            payeeActivity
        }
    }
}

export const fetchSubscribeToPayeeActivityInDateRange = (
    payeeId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payouts placed in the last 30 days
    toDate=Date.now() + (DAY_IN_MILLISECONDS * 30) //go a month into the future
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeActivityRef = query(
                            collection(firestore, "payeeActivity"), 
                            where("payeeId", "==", payeeId), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate)
                        )
    return async (dispatch) => {
        try{
            const payeeActivityListener = await onSnapshot(payeeActivityRef,
                querySnapshot =>{
                    //get an array of payee activity from the snapshot
                    const payeeActivity = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeActivity(payeeActivity))
                }
            )
            return payeeActivityListener
        } catch (e){
            const message = `action > payeeActivity > fetchSavePayeeActivityInDateRange: Failed to save payee activity for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSavePayeeActivityForDisbursement = (
    disbursementId,
    payeeId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeActivityRef = query(
                            collection(firestore, "payeeActivity"), 
                            where("payeeId", "==", payeeId), 
                            where("disbursementId", "==",  disbursementId)
                        )
    return async (dispatch) => {
        try{
            const querySnapshot = await getDocs(payeeActivityRef)
            //get an array of payee activity objects from the snapshot
            const payeeActivity = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayeeActivity(payeeActivity))
            return true
        } catch (e){
            const message = `action > payeeActivity > fetchSavePayeeActivityForDisbursement: Failed to save payee activity for ${payeeId} and disbursementId ${disbursementId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
