import { capitalizeAllWords } from "./stringUtils"
export const getPayeeLink = (payeeHandleId) => {
    return `${window.location.origin}/${payeeHandleId}`
}

export const addressObjectToString = (address, addDirections = false) => {
    if (!address) return ""
    let addressLine = ""
    if (address.line1) addressLine = `${addressLine}${address.line1}, `
    if (address.line2) addressLine = `${addressLine}${address.line2}, `
    if (address.usaCity) addressLine = `${addressLine}${address.usaCity}, `
    if (address.city) addressLine = `${addressLine}${address.city}, `
    if (address.zipCode) addressLine = `${addressLine}${address.zipCode}, `
    addressLine = addressLine ? capitalizeAllWords(`${addressLine} ${address.countryName}`) : ""
    if (addDirections && Boolean(address.directions)) addressLine = `${addressLine}: ${address.directions}, `
    return addressLine
}