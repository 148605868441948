import React from "react"
import {INVOICE_STATUS_VIEWED, INVOICE_STATUS_PAID, INVOICE_STATUS_CANCELLED} from "../../constants/invoices"

const InvoiceBadge = ({currentStatus=""}) => {
    //show a different badge style based on the invoice's current status
    const badgeStyle = currentStatus === INVOICE_STATUS_VIEWED ?
                        "info" :
                        currentStatus === INVOICE_STATUS_PAID ?
                        "success" :
                        currentStatus === INVOICE_STATUS_CANCELLED ?
                        "error"
                        : ""
    return (
        <span className={`badge ${badgeStyle}`}>{currentStatus}</span>
    )
}

export default InvoiceBadge