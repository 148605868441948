import React from "react"
import QRCode from "react-qr-code";
import LinkDisplay from "../../components/LinkDisplay";
import IconButton from "../../components/IconButton"
import CameraApp from "../../images/camera-app-icon.jpg"

import withPopover from "../../hocs/withPopover"
import {shareNativePopup, shareViaEmail, shareViaWhatsapp, copyToClipboard} from "../../utils/interactionUtils"
import {isMobile} from "../../utils/deviceUtils"

const IconButtonWithPopover = withPopover((props) => {
    const {link, showPopover} = props
    return <IconButton {...props} onClick={e=>{copyToClipboard(link); showPopover(e)}} icon="content-copy" label="Copy" buttonStyle={{"backgroundImage": "var(--primary-gradient)", "color": "var(--white)"}} />
})
const QRShareLink = ({
    link="",
    paymentRequestMessage="Pay me via Shopdm Pay"
}) => {

    return (
        <div>
            <div className="h2 text-align-center margin-bottom-05em display-flex justify-content-center align-items-center" >
                <img src={CameraApp} height="40px" width="40px" alt="camera-app" className="margin-right-05em"/> 
                Use Camera To Scan & Pay
            </div>
            <div className="display-flex justify-content-center align-items-center margin-bottom-05em">
                <QRCode value={link} style={{ height: "auto", maxWidth: "256px", width: "256px" }}/>
            </div>
            <div className="h2 margin-bottom-05em">Or Share Payment Link</div>
            <LinkDisplay link={link}/>
            <div className="display-flex justify-content-space-around margin-top-05em">
                {
                    // on mobile devices use the native popup
                    //on web just show the Whatsapp share
                    isMobile() ?
                    <IconButton onClick={() => shareNativePopup(link, paymentRequestMessage) } icon="share" label="Share" buttonStyle={{"backgroundImage": "var(--primary-gradient)", "color": "var(--white)"}}/>
                    :
                    <IconButton onClick={()=>shareViaWhatsapp(`${paymentRequestMessage}: ${link}`) } icon="whatsapp" label="Whatsapp" buttonStyle={{"backgroundImage": "var(--primary-gradient)", "color": "var(--white)"}}/>
                }
                <IconButton onClick={()=>shareViaEmail({body: `${paymentRequestMessage}: ${link}`}) } icon="email" label="Email" buttonStyle={{"backgroundImage": "var(--primary-gradient)", "color": "var(--white)"}}/>
                <IconButtonWithPopover link={link} popoverIcon="content-copy" popoverText="Copied!"/>
            </div>
        </div>
    )
}

export default QRShareLink