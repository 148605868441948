import currency from 'currency.js'
const {FAC_PAYMENT_PROCESSING_FEE} = require('../constants/fac')
export const calculateTransactionFees = (totalXcd, feeSettings={})=> {
    //calculate fees for invoices and one time payments created via paydm
    let {
        senderFee, 
        recipientFee //e.g. 0.06 to represent a 6% fee
    } = feeSettings
    senderFee = senderFee ? senderFee : 0
    recipientFee = recipientFee ? recipientFee : 0
    //the fee to be be paid by the merchant (the recipient)
    let recipientFeeXcd = currency(totalXcd).multiply(recipientFee).value
    //the absolute total which the customer will pay
    let totalPlusFeesXcd = totalXcd
    //the fee to be paid by the customer of the merchant (the sender)
    //this will only be more than zero if the merchant is passing on part of the fee 
    let senderFeeXcd = 0
    //the fee that will be taken by republic bank/FAC
    let paymentProcessorFeeXcd = 0
    if (Number(senderFee) === 0){
        //if the sender is not paying anything, then the payment processor's fee is simple
        //we just get 3% of the overall total
        paymentProcessorFeeXcd = currency(totalXcd).multiply(FAC_PAYMENT_PROCESSING_FEE).value
    }
    else {
        //if the sender is absorbing some of the fee,
        // then the payment processors 3% will apply to both the merchant's total AND the 
        //fee paid by the customer/sender

        //first, we find the amount that the payment processor will apply their fees to
        //that is, the total plus sender fees
        //this is a little complicated, since we have to handle both the case where
        //the sender is paying half of the fee (3%) and the case where they are paying the whole fee (6%)
        const totalModifier = (senderFee - FAC_PAYMENT_PROCESSING_FEE) * Number(totalXcd)
        const modifiedTotal = currency(totalXcd).add(totalModifier).value
        paymentProcessorFeeXcd = currency(modifiedTotal)
                                         .divide(1 - FAC_PAYMENT_PROCESSING_FEE)
                                         .subtract(modifiedTotal)
                                         .value
        //then we find the dollar value of the fee to be paid by the sender
        senderFeeXcd = currency(totalModifier).add(paymentProcessorFeeXcd).value
        //and the total plus fees is everything that will need to be paid by the customer
        totalPlusFeesXcd = currency(modifiedTotal).add(paymentProcessorFeeXcd).value
    } 
    return {
        recipientFeeXcd,
        senderFeeXcd,
        paymentProcessorFeeXcd,
        totalPlusFeesXcd,
    }
}