import React from "react"

import DataTable from "../../components/DataTable";
import InvoiceActionsMenu from "../InvoiceActionsMenu";
import InvoiceBadge from "../../components/InvoiceBadge";
import EllipsisText from "../../components/EllipsisText";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import InvoiceQuickActionButton from "../../components/InvoiceQuickActionButton";

import {shortenReference} from "../../utils/interfaceUtils";
import {copyToClipboard} from "../../utils/interactionUtils";
import {timestampToDateString} from "../../utils/datetimeUtils";

const InvoiceTable = ({
                          title = "Invoices",
                          payeeHandleId = "",
                          emptyText = "No Invoices",
                          invoicesArray = [],

                      }) => {
    invoicesArray = invoicesArray.map(i => ({
        ...i,
        customerName: i.billToCustomer ? i.billToCustomer.name : ""
    }))
    return (
        <DataTable
            dataArray={invoicesArray}
            fields={[
                {
                    id: 'reference',
                    header: 'Reference',
                    transformFunction: reference => <a className="actionText"
                                                       onClick={() => copyToClipboard(shortenReference(reference))}>{shortenReference(reference)}</a>
                },
                {
                    id: 'totalXcd',
                    header: 'Total',
                    transformFunction: totalXcd => <div className="font-weight-600">EC$<AmountSmallAfterDecimal
                        amount={totalXcd}/></div>
                },
                {
                    id: 'currentStatus',
                    header: 'Status',
                    transformFunction: currentStatus => <InvoiceBadge currentStatus={currentStatus}/>
                },
                {
                    id: 'customerName',
                    header: "Name"
                },
                {
                    id: 'reason',
                    header: 'Reason',
                    transformFunction: reason => <div className="text-align-center"><EllipsisText charDisplayLength={30}
                                                                                                  text={reason}/></div>
                },
                {
                    id: 'createdAt',
                    header: 'Created At',
                    transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                },
                {
                    id: 'id', //quickAction
                    header: "",
                    hideSort: true,
                    transformFunction: (id, key, i, invoice) => <InvoiceQuickActionButton invoice={invoice}
                                                                                          payeeHandleId={payeeHandleId}/>
                },
                {
                    id: 'id', //actions
                    header: "",
                    hideSort: true,
                    transformFunction: (id, key, i, invoice) => <InvoiceActionsMenu invoice={invoice}
                                                                                    iconHasShadow={false}
                                                                                    payeeHandleId={payeeHandleId}/>
                }
            ]}
            title={title}
            emptyText={emptyText}
            defaultSortBy="createdAt"
            searchFields={["reference", "currentStatus", "customerName", "reason"]}
        />
    )
}

export default InvoiceTable