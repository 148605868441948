import React, {Component} from 'react'
import styles from './DataTable.module.css'
import DataTableRow from './DataTableRow'
import SortButton from '../SortButton'
import Pagination from "../Pagination"
import MultiSearchField from "../MultiSearchField"
import {stringBContainsStringA} from "../../utils/stringUtils"
import {SORT_DIRECTION_ASC, SORT_DIRECTION_DESC} from '../../constants/analysis'
export default class DataTable extends Component {

    constructor(props){
        super(props)
        this.state ={
            sortBy: null,
            sortDirection: SORT_DIRECTION_ASC,
            currentPageIndex: 0,
            searchTerm: "",
            searchBy: props.searchFields && props.searchFields.length > 0 ? props.searchFields[0] : ""
        }
    }
    
    static defaultProps = {
        errors: {},
        emptyText: "No Data",
        defaultSortDirection: SORT_DIRECTION_DESC,
        maxRowsPerPage:30,
        paginate: false,
        rowHighlightFunction: () => {},
        tableContainerStyles: {},
        showRowIndices: false
    }
    
    sortData = (dataArray, sortBy=this.state.sortBy, sortDirection=this.state.sortDirection) => {
        if (!sortBy) return dataArray

        const formatString = str => {
            if (!str) return ""
            return str.trim().toLowerCase()
        }
        const sortedArray = [...dataArray]
        sortedArray.sort((a, b) => {
            //get the sortBy value, 
            let aSortBy
            let bSortBy
            //if an attribute name was provided pull out its value
            if (typeof sortBy === 'string'){
                aSortBy = a[sortBy]
                bSortBy = b[sortBy]
            } 
            //else if a function is provided, process the object to get the value
            else if (typeof sortBy === 'function'){
                aSortBy = sortBy(a)
                bSortBy = sortBy(b)
            } 
            
            if (!isNaN(aSortBy)){
                const aValue = !isNaN(aSortBy) ? aSortBy : 0
                const bValue = !isNaN(bSortBy) ? bSortBy : 0
                if (sortDirection === SORT_DIRECTION_DESC) return bValue - aValue
                else if (sortDirection === SORT_DIRECTION_ASC) return aValue - bValue
            }
            else if (typeof aSortBy === "string") {
                if (sortDirection === SORT_DIRECTION_ASC){
                    if(formatString(aSortBy) < formatString(bSortBy)) { return -1; }
                    if(formatString(aSortBy) > formatString(bSortBy)) { return 1; }
                    return 0;
                } else if (sortDirection === SORT_DIRECTION_DESC){
                    if(formatString(aSortBy) > formatString(bSortBy)) { return -1; }
                    if(formatString(aSortBy) < formatString(bSortBy)) { return 1; }
                    return 0;
                }
            }
        })
        return sortedArray
    }

    handleSortButtonPress = (sortBy, sortDirection) => this.setState({sortBy, sortDirection})

    getHeader = () => {
        const {fields, showRowIndices} = this.props
        const {sortBy, sortDirection} = this.state
        return (
        <tr className={styles.headerContainer}>
            { 
                showRowIndices ?
                <th key="indexheader" className={styles.th}></th>
                : 
                null
            }
            {
                fields.map(
                    field => <th key={`${field.id}${field.header}header`} className={styles.th}>
                                <div>
                                    {field.header}
                                    {
                                        !field.hideSort ?
                                        <SortButton
                                            onClickUp={()=>this.handleSortButtonPress(field.id, SORT_DIRECTION_DESC)}
                                            onClickDown={()=>this.handleSortButtonPress(field.id, SORT_DIRECTION_ASC)}
                                            hide={field.id !== sortBy ? "" : sortDirection === SORT_DIRECTION_ASC ? "bottom" : sortDirection === SORT_DIRECTION_DESC ? "top" : ""}
                                        />
                                        :
                                        null
                                    }
                                </div>
                            </th>
                )
            }
        </tr>
        )
    }

    getRows = (dataArray) => {
        const {errors, fields, showRowIndices, maxRowsPerPage, onClick, selectedRowId, rowHighlightFunction} = this.props
        const {currentPageIndex} = this.state
        return dataArray.map((data, index) => {
            const error = errors[data.id]
            return <DataTableRow
                    key={data.id}
                    data={data}
                    fields={fields}
                    index={index + (currentPageIndex * maxRowsPerPage)}
                    showRowIndices={showRowIndices}
                    even={((index % 2) > 0)}
                    error={error}
                    onClick={onClick}
                    selected={data.id === selectedRowId}
                    rowHighlightFunction={rowHighlightFunction}
                />
        })
    }

    handleChangePageIndex = currentPageIndex => {
        if (this.table) {
            this.table.scrollTo(0, 0)
            window.scrollTo(0,this.table.offsetTop - 100)
        }
        this.setState({currentPageIndex})
    }

    filterBySearch = dataArray => {
        const {searchTerm, searchBy} = this.state
        if (!searchTerm.trim()) return dataArray
        if (!searchBy) {
            console.warn("There is no search by")
            return dataArray
        }
        return dataArray.filter(data => {
            return stringBContainsStringA(searchTerm, String(data[searchBy]))
        })
    }

    handleChangeSearchTerm = searchTerm => this.setState({
        searchTerm,
        currentPageIndex: 0
    })

    render (){
        const {dataArray, emptyText, title, TopRight, defaultSortBy, defaultSortDirection, maxRowsPerPage, paginate, fields, searchFields, tableContainerStyles} = this.props
        if (!dataArray) return null
        const {currentPageIndex, searchTerm, sortBy, searchBy} = this.state
        let data = searchBy ? this.filterBySearch(dataArray) : dataArray
        data = (!sortBy && defaultSortBy && defaultSortDirection) ? this.sortData(data, defaultSortBy, defaultSortDirection): this.sortData(data) 
        let startRowIndex = currentPageIndex * maxRowsPerPage
        startRowIndex = startRowIndex > data.length ? 0 : startRowIndex
        const endRowIndex = (currentPageIndex + 1) * maxRowsPerPage
        const displayData = paginate ? data.slice(startRowIndex, endRowIndex) : data
        const fieldIdToHeaderMap = fields.reduce((map, field) => {
            map[field.id] = field.header
            return map
        }, {})
        return (
            <div className={styles.container}>
                <div className={styles.tableTop}>
                    {
                        title || searchBy?
                        <div className={styles.title}>
                            {title ? <div>{title} <strong>({data.length})</strong></div> : ""}
                            { 
                                searchBy && fieldIdToHeaderMap[searchBy] ?
                                <MultiSearchField
                                    searchTerm={searchTerm}
                                    searchFields={searchFields.map(field => ({key: field, label: fieldIdToHeaderMap[field]}))}
                                    searchBy={searchBy}
                                    onChangeSearchTerm={this.handleChangeSearchTerm}
                                    onChangeSearchBy={searchBy => this.setState({searchBy})}
                                />
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    {
                        TopRight ? 
                        <TopRight/>
                        :
                        null
                    }
                </div>
                <div className={styles.tableContainer}
                    ref={el => this.table = el}
                    style={tableContainerStyles}
                >
                    <table className={styles.table}
                    >
                        <thead>
                        {this.getHeader()}
                        </thead>
                        
                        <tbody>
                            {this.getRows(displayData)}  
                        </tbody>
                        
                    </table>
                    {
                        dataArray.length === 0 ?
                        <div className={styles.noData}>{emptyText}</div>
                        :
                        null
                    }
                </div>
                {
                    paginate && (data.length > maxRowsPerPage) ?
                    <div className={styles.paginationContainer}>
                        <Pagination 
                            itemCount={data.length}
                            maxItemsPerPage={maxRowsPerPage}
                            currentPageIndex={currentPageIndex}
                            onSelectIndex={this.handleChangePageIndex}
                        />
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}