import {COUNTRY_CODES} from "../constants/country"

export const isValidEmail = email => email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/g)
export const isValidLegalName = name => name.replace(/^\s+/, "").replace(/\s+$/, "").replace(/\s+/, " ")
export const validateAddress = (address={}) => {
    const errors = {}
    if (!address.line1) errors.line1 = "Please provide a street address"
    if (!address.city) errors.city = "Please provide a city"
    const country = COUNTRY_CODES.find(c => c.code === address.countryCode) 
    if (
        !country ||
        !address.countryCode || 
        !address.countryName
    ) errors.countryCode = "Please select a country"
    if (
        !address.zipCode &&
        country && 
        !country.noZipCode
    ) errors.zipCode = "Please provide a zip / postal code"
    return errors
}
export const handleChangeCurrencyValue = (e, min=null, max=null, ) => {
    let value = e.target.value
    if (value === "") value = value
    else {
        const minSatisfied = typeof min !== 'number' || value >= min
        const maxSatisfied = typeof max !== 'number' || value <= max
        if(
            minSatisfied && 
            maxSatisfied &&
            value.match(/^(0|([1-9][0-9]*))?(\.|\.[0-9]?[0-9])?$/g)
        ) return value
        else return undefined
    }
    
    return value
}