import React from "react";

import Form from "../../components/Form";
import FormField from "../../components/FormField"

import {capitalizeAllWords} from "../../utils/stringUtils"
import {validateAddress, isValidEmail, isValidLegalName} from "../../utils/formValidationUtils"
import {matchIsValidTel} from 'mui-tel-input'

import {connect} from "react-redux"

class CustomerForm extends Form{

    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields, ...props.defaultValues},
            errors: {},
        }
    }

    fields = {
        name: "",
        phoneNumber: "+1767",
        address: {
            line1: "",
            city: "",
            countryName: "Dominica",
            countryCode: "DM",
            zipCode: ""
        },
        email: "",
    }

    static defaultProps = {
        title: "Customer Form",
        buttonText: 'Save',
        onSubmit: ()=>{},
        defaultValues: {}
    }

    executeSubmit = async () => {
        const {onSubmit, actions} = this.props
        const {name, phoneNumber, address, email}= this.state.values
        return await onSubmit(
            capitalizeAllWords(name.trim()),
            phoneNumber.trim(),
            address,
            email.trim(),
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    validate = () => {
        const errors = {}
        const {values} = this.state
        let {name, phoneNumber, address, email} = values
        const {payeeCustomers, payeeId} = this.props
        name = name.trim()
        phoneNumber = phoneNumber.trim()
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                    key !== "email" 
                ){
                const label = key === "phoneNumber" ? "Phone number" : capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["name"] && !isValidLegalName(name)) errors['name'] = 'Name is invalid'
        if (!errors["phoneNumber"] && !matchIsValidTel(phoneNumber)) errors['phoneNumber'] = 'Phone number is invalid'
        if (!errors["phoneNumber"]){
            const previousCustomer = Object.values(payeeCustomers.payeeCustomersById)
                                           .filter(c => c.payeeId === payeeId)
                                           .find(c => c.phoneNumber === phoneNumber)
            if (previousCustomer) errors["phoneNumber"] = `This phone number has already been used by ${previousCustomer.name}`
        }
        const addressErrors = validateAddress(address)
        if (Object.keys(addressErrors).length > 0){
            errors["address"] = Object.values(addressErrors)[0]
            errors.addressErrorObject = addressErrors
        }
        //if the email is set, ensure it is valid
        if (email){
            if (!isValidEmail(email)) errors.email = "Email is invalid"
        }
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    }

    handleSubmitSuccess = customer => {
        const {onSuccess=()=>{}} = this.props
        onSuccess(customer.id)
    }
    
    render(){
        const {values, errors} = this.state
        const {name, phoneNumber, address, email} = values
        const {title, buttonText} = this.props
        return (
            <div>
                <div className="h2 text-align-center">{title}</div>
                <FormField 
                    value={name} 
                    label="Name"
                    onChange={e => this.handleChange(e, "name")}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.name}
                />
                <FormField 
                    label="Phone"
                    type="tel" 
                    value={phoneNumber}
                    onChange={phoneNumber => this.handleChangeSelect(phoneNumber, 'phoneNumber')} 
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.phoneNumber}
                />
                <FormField 
                    label="Address"
                    type="address" 
                    value={address}
                    onChange={address => this.handleChangeSelect(address, 'address')} 
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.address}
                    errorObject={errors.addressErrorObject}
                />
                <FormField 
                    label="Email (optional)"
                    value={email} 
                    onChange={e => this.handleChange(e, "email")}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.email}
                />
                <button className="button secondary width-100pct" onClick={this.handleSubmit}>{buttonText}</button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    payeeCustomers: state.payeeCustomers,
})

export default connect(mapStateToProps)(CustomerForm)