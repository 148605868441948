import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, getDocs, onSnapshot, doc, setDoc} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS} from "../constants/datetime"

export const SAVE_PAYEE_CUSTOMERS = 'SAVE_PAYEE_CUSTOMERS'
export const CREATE_PAYEE_CUSTOMER = 'CREATE_PAYEE_CUSTOMER'

export const savePayeeCustomers = (payeeCustomers, payeeId, lastLoadedAt=0) => {
    return {
        type: SAVE_PAYEE_CUSTOMERS,
        payload: {
            payeeCustomers,
            payeeId,
            lastLoadedAt
        }
    }
}

export const createPayeeCustomer = payeeCustomer => {
    return {
        type: CREATE_PAYEE_CUSTOMER,
        payload: {
            payeeCustomer
        }
    }
}


export const fetchSavePayeeCustomers = (
    payeeId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCustomersRef = query(
                            collection(firestore, "payeeCustomers"), 
                            where("payeeId", "==", payeeId), 
                        )
    return async (dispatch, getState) => {
        try{
            const {payeeCustomers} = getState()
            //only load payee customers once a day
            const lastLoadedAt = payeeCustomers.lastLoadedByPayeeId[payeeId]
            if (lastLoadedAt && ((Date.now() - lastLoadedAt) <= DAY_IN_MILLISECONDS)) return true
            const querySnapshot = await getDocs(payeeCustomersRef)
            //get an array of payee customer objects from the snapshot
            const payeeCustomersList = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayeeCustomers(payeeCustomersList, payeeId, Date.now()))
            return true
        } catch (e){
            const message = `action > payeeCustomers > fetchSavePayeeCustomers: Failed to save payee customers for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayeeCustomersEditedAfterDate = (
    payeeId,
    fromDate=(Date.now() - DAY_IN_MILLISECONDS) //defaults to reading payee's edited in the last day
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCustomersRef = query(
                            collection(firestore, "payeeCustomers"), 
                            where("payeeId", "==", payeeId),
                            where("lastEditedAt", ">=", fromDate) 
                        )
    return async (dispatch) => {
        try{
            const payeeCustomersListener = await onSnapshot(payeeCustomersRef,
                querySnapshot =>{
                    //get an array of payee customers from the snapshot
                    const payeeCustomers = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCustomers(payeeCustomers, payeeId))
                }
            )
            return payeeCustomersListener
        } catch (e){
            const message = `action > payeeCustomers > fetchSubscribeToPayeeCustomersEditedAfterDate: Failed to subscribe to payee customers for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchCreatePayeeCustomer = (
    id, 
    payeeId,
    name,
    phoneNumber,
    address,
    email,
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const payeeCustomersRef = doc(firestore, "payeeCustomers", id)
    const payeeCustomer = {
        id, 
        payeeId,
        name,
        phoneNumber,
        address,
        email,
        createdAt: Date.now(),
        lastEditedAt: Date.now(),
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            payeeCustomer.createdByUserId = user.id ? user.id : null
            await setDoc(payeeCustomersRef, payeeCustomer)
            dispatch(createPayeeCustomer(payeeCustomer))
            onSuccess(payeeCustomer)
            return true
        } catch (e){
            const message = `action > payeeCustomers > fetchCreatePayeeCustomer: Failed to create payee customer ${JSON.stringify(payeeCustomer)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(payeeCustomer)
            return false
        }
        
    }
}
