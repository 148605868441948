import React, {useEffect, useState} from "react"

import DataTable from "../../components/DataTable"
import Modal from "../../components/Modal"
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal"

import PayeeActivityDateList from "../PayeeActivityDateList"

import {timestampToDateString} from "../../utils/datetimeUtils"
import { copyToClipboard } from "../../utils/interactionUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const DisbursementTable = ({
    payeeId,
    title="Disbursements",
    emptyText="No Disbursements",
    disbursementsArray=[],
    disbursements,
    payeeActivity,
    actions
}) => {
    //used to show the transaction modal
    const [disbursementId, setDisbursementId] = useState("")
    const disbursement = disbursements.disbursementsById[disbursementId]
    //load all of the payee activity transactions that were involved in the selected disbursement
    const loadPayeeActivityForDisbursement = async () => {
        actions.toggleLoading(true)
        const success = await actions.fetchSavePayeeActivityForDisbursement(
            disbursementId,
            payeeId
        )
        actions.toggleLoading(false)
        if (!success) alert(`ERROR: failed to load transactions, check your internet connection and try again`)
    }
    useEffect(() => {
        //load the payee activity objects/transactions associated with this disbursement
        if (disbursementId){
            loadPayeeActivityForDisbursement()
        }
    }, [disbursementId])
    //filter the payee activity list to only those for this disbursement and this payee order descrending
    const payeeActivityList = Object.values(payeeActivity.payeeActivityById)
                              .filter(
                                  a => a.payeeId === payeeId &&
                                       a.disbursementId === disbursementId
                              )
                              .sort((aA, aB) => aB.createdAt - aA.createdAt)
    return (
        <React.Fragment>
            <DataTable 
                dataArray={disbursementsArray}
                fields={[
                    {id: 'reference', header: 'Bank Deposit Reference', transformFunction: reference => <a className="actionText" onClick={() => copyToClipboard(reference)}>{reference}</a>},
                    {id: 'totalXcd', header: 'Total Paid', transformFunction: totalXcd => <div className="font-weight-600">EC$<AmountSmallAfterDecimal amount={totalXcd} /></div>},
                    {id: 'id', header: 'Transactions', hideSort: true, transformFunction: id => <button className="button secondary" onClick={() => setDisbursementId(id)}>See Transactions</button>},
                    {id: 'createdAt', header: 'Paid At', transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")},
                    {id: 'accountNumber', header: 'Paid To'},
                ]}
                title={title}
                emptyText={emptyText}
                defaultSortBy="createdAt"
                searchFields={["reference", "totalXcd", "accountNumber"]}
            />
            
            <Modal
                isOpen={Boolean(disbursement)}
                closeModal={() => setDisbursementId("")}
                showCloseButton={false}
                className="height-fit-content max-height-100pct"
                overlayClassName="flex-center padding-1pct"
                innerClassName="max-height-80vh overflow-y-auto"
                closeOnOverlayClick={true}
            > 
                {
                    disbursement ? 
                    <div className="margin-bottom-1em">
                        <div className="h1">Ref# {disbursement.reference}</div> 
                        <div>Total Paid: <span className="font-weight-600">EC$<AmountSmallAfterDecimal amount={disbursement.totalXcd} /></span></div>
                        <div className="font-size-14px color-grey">Paid on {timestampToDateString(disbursement.createdAt, "EEE, dd MMMM 'at' t")}</div>
                        <div className="font-size-14px color-grey">Paid to {disbursement.accountNumber}</div>
                    </div>
                    :
                    ""
                }
                <PayeeActivityDateList 
                    payeeActivityList={payeeActivityList} 
                />
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    payeeActivity: state.payeeActivity,
    disbursements: state.disbursements
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DisbursementTable)