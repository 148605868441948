import {firebaseApp} from "../config/firebase"
import { getFirestore, query, collection, onSnapshot, where} from "firebase/firestore"

import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_DISBURSEMENTS = 'SAVE_DISBURSEMENTS'

export const saveDisbursements = disbursements => {
    return {
        type: SAVE_DISBURSEMENTS,
        payload: {
            disbursements
        }
    }
}

export const fetchSubscribeToPayeeDisbursementsInDateRange = (
    payeeId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all disbursements placed in the last 30 days
    toDate=Date.now() + (DAY_IN_MILLISECONDS * 30) //default to a month into the future
 ) => {
    const firestore = getFirestore(firebaseApp)
    const disbursementsRef = query(
                            collection(firestore, "disbursements"), 
                            where("payeeId", "==", payeeId), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate)
                        )
    return async (dispatch) => {
        try{
            const disbursementsListener = await onSnapshot(disbursementsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const disbursements = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveDisbursements(disbursements))
                }
            )
            return disbursementsListener
        } catch (e){
            const message = `action > disbursements > fetchSavePayeeDisbursementsInDateRange: Failed to save disbursements for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}