import {logError} from "./errorHandlingUtils"

export const stringBContainsStringA = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        logError(new Error(`stringBContainsStringA: stringA ${JSON.stringify(stringA)} is not a string`))
        return 
    }
    if (! (typeof stringB === 'string')) {
        logError(new Error(`stringBContainsStringA: stringB ${JSON.stringify(stringB)} is not a string`))
        return
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+|\'+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+|\'+/g, '')
    return stringB.includes(stringA)
}

export const capitalizeAllWords = text => {
    if (! (typeof text === 'string')) {
        console.warn(`capitalizeAllWords: text ${JSON.stringify(text)} is not a string`)
        return ""
    }
    return text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const alphabeticalSort = (stringA, stringB) => {
    if (! (typeof stringA === 'string')) {
        console.warn(`alphabeticalSort: stringA ${JSON.stringify(stringA)} is not a string. String B is ${JSON.stringify(stringB)}`)
        return 0
    }
    if (! (typeof stringB === 'string')) {
        console.warn(`alphabeticalSort: stringB ${JSON.stringify(stringB)} is not a string. String A is ${JSON.stringify(stringA)}`)
        return 0
    }
    stringA = stringA.toLowerCase().replace(/ +?|\.+/g, '')
    stringB = stringB.toLowerCase().replace(/ +?|\.+/g, '')
    if ( stringA < stringB ){
        return -1;
      }
      if ( stringA > stringB ){
        return 1;
      }
      return 0;
}