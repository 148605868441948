import React from "react"

import DataTable from "../../components/DataTable";

import {timestampToDateString} from "../../utils/datetimeUtils";
import {shortenReference} from "../../utils/interfaceUtils";
import { isMobile } from "../../utils/deviceUtils";
const CustomerTable = ({
                          title = "Customers",
                          emptyText = "No Customers",
                          customersArray = [],

                      }) => {
    return (
        <DataTable
            dataArray={customersArray}
            fields={[
                {
                    id: 'name',
                    header: "Name"
                },
                {
                    id: 'phoneNumber',
                    header: 'Phone',
                },
                {
                    id: 'email',
                    header: 'Email',
                },
                {
                    id: 'lastPaidAt',
                    header: 'Last Paid At',
                    transformFunction: lastPaidAt => lastPaidAt? timestampToDateString(lastPaidAt, "EEE, dd MMMM 'at' t") : ""
                },
                {
                    id: 'lastReference',
                    header: 'Last Reference',
                    transformFunction: lastReference => lastReference ? shortenReference(lastReference) : ""   
                },
                {
                    id: 'createdAt',
                    header: 'Created At',
                    transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                },
            ]}
            title={title}
            emptyText={emptyText}
            defaultSortBy="lastPaidAt"
            searchFields={["name", "lastReference", "phoneNumber", "email"]}
            maxRowsPerPage={isMobile() ? 4: 8}
            paginate={true}
        />
    )
}

export default CustomerTable