import React, {useState, useEffect} from "react"

import InvoiceFormWithQRScanModal from "../../../containers/InvoiceFormWithQRScanModal"
import PendingInvoicesDateList from "../../../containers/PendingInvoicesDateList"
import PayeeActivityDateList from "../../../containers/PayeeActivityDateList"
import AmountSmallAfterDecimal from "../../../components/AmountSmallAfterDecimal"
import styles from "./Home.module.css"

import {Helmet} from "react-helmet";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import { capitalizeAllWords } from "../../../utils/stringUtils"

import useBalance from "../../../hooks/useBalance";

const Home = ({payeeId, payees, payouts, payeeActivity, actions}) => {
    /** calculate the current balance */
    const balance = useBalance({payeeId, payouts, actions})
    const payee = payees.payeesById[payeeId]
    //listen to payee activity for the payee
    const [payeeActivityListener, setPayeeActivityListener] = useState(()=>()=>{})
    const listenToPayeeActivity = async () => {
        const newPayeeActivityListener = await actions.fetchSubscribeToPayeeActivityInDateRange(payeeId)
        setPayeeActivityListener(() => newPayeeActivityListener)
    }
    useEffect(() => {
        listenToPayeeActivity()
        return () => {
            if (typeof payeeActivityListener === "function") payeeActivityListener()
        }
    }, [payeeId])
    //filter the payee activity list to only those for this payee order descrending
    const payeeActivityList = Object.values(payeeActivity.payeeActivityById)
                              .filter(
                                  a => a.payeeId === payeeId
                              )
                              .sort((aA, aB) => aB.createdAt - aA.createdAt)
    if (!payee) return ""
    const payeeName = capitalizeAllWords(payee.name)
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Home - Shopdm Pay </title>
            </Helmet>
            <div className={styles.header} >
                <div>  
                    <div className="h2">Balance</div>
                    <div className="amount big">EC $<AmountSmallAfterDecimal amount={Number(balance).toFixed(2)}/></div>
                </div>
                <div className={styles.invoiceForm}>
                    <InvoiceFormWithQRScanModal 
                        payeeId={payeeId} 
                        payeeHandleId={payee.handleId}
                        paymentRequestMessage={`Pay ${payeeName} via Shopdm Pay`}
                        transactionLimitXcd={payee.transactionLimitXcd}
                    />
                </div>  
            </div>
            <div>
                <PendingInvoicesDateList payeeId={payeeId} payeeHandleId={payee.handleId} payeeName={payeeName}/>
            </div>
            <div>
                <PayeeActivityDateList payeeActivityList={payeeActivityList} showRight={false}/>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    invoices: state.invoices,
    payees: state.payees,
    payouts: state.payouts,
    payeeActivity: state.payeeActivity
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);