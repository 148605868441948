import React from "react"
import styles from "./CardProcessingStatus.module.css"

import {

    CARD_STATUS_APPROVED,
    CARD_STATUS_DECLINED,
    CARD_STATUS_TIME_OUT,
    CARD_STATUS_ERROR,
    CARD_STATUS_LOADING
} from "../../constants/cardProcessing"

import CardApproved from "../../images/card-approved.png"
import CardDeclined from "../../images/card-declined.png"
import Timeout from "../../images/timeout.png"
import SystemError from "../../images/system-error.png"

import LoadAnimation from "../LoadAnimation"

const statusToImgMap = {
    [CARD_STATUS_APPROVED] : CardApproved,
    [CARD_STATUS_DECLINED] : CardDeclined,
    [CARD_STATUS_TIME_OUT] : Timeout,
    [CARD_STATUS_ERROR]: SystemError
}

const statusToDefaultTitleMap = {
    [CARD_STATUS_APPROVED] : "Your payment was successful",
    [CARD_STATUS_DECLINED] : "Your payment failed",
    [CARD_STATUS_TIME_OUT] : "Sorry, your transaction timed out",
    [CARD_STATUS_ERROR]: "Something went wrong"
}

const statusToDefaultMessageMap = {
    [CARD_STATUS_APPROVED] : "Thank you. Please come back again soon",
    [CARD_STATUS_DECLINED] : "Your account was not debited. Please try again or contact your bank",
    [CARD_STATUS_TIME_OUT] : "Your account was not debited. Please try again when you're ready",
    [CARD_STATUS_ERROR]: "Your account was not debited. Please check your connection and try again"
}

const CardProcessingStatus = ({status="", statusTitle="", statusMessage=""}) => {
    if (!status) return ""
    return (
        <div className={styles.container}>
            <div className="text-align-center">
                <div className={styles.icon}>
                    {
                        status === CARD_STATUS_LOADING ?
                        <LoadAnimation />
                        : 
                        status && statusToImgMap[status]?
                        <img src={statusToImgMap[status]}/>
                        :
                        ""
                    }
                </div>
                <div className={styles.statusTitle}>
                    {
                        statusTitle ? 
                        statusTitle :
                        statusToDefaultTitleMap[status] ? 
                        statusToDefaultTitleMap[status]
                        :
                        ""
                    }
                </div>
                <div className={styles.statusMessage}>
                    {
                        statusMessage ? 
                        statusMessage :
                        statusToDefaultMessageMap[status] ? 
                        statusToDefaultMessageMap[status]
                        :
                        ""
                    }
                </div>
            </div>
        </div>
    )
}

export default CardProcessingStatus