import React from "react";
import {NavLink} from "react-router-dom"
import Icon from "../../components/Icon"

import styles from "./MobileNavItem.module.css"

const MobileNavItem = ({to="", icon="", label=""}) => {
    return (
        <li className={`mobileNavItem`}>
            <NavLink className="mobileNavLink" end to={to}>
                <span><Icon icon={icon}/></span>
                <span className={styles.label}>{label}</span>
            </NavLink>
        </li>
    )
}

export default MobileNavItem