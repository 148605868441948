import React from "react"
import MerchantHome from "../MerchantScreens/Home"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import { useOutletContext } from 'react-router-dom';

const Home = ({}) => {
    const {payeeId} = useOutletContext()
    return (
        <div>
            {
                payeeId ? 
                <MerchantHome payeeId={payeeId}/>
                :
                <div>No merchant account</div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    payees: state.payees
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);