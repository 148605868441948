import React from "react"

import {handleChangeCurrencyValue} from "../../utils/formValidationUtils"

class CurrencyInput extends React.Component {
    static defaultProps = {
        currency: "EC",
        currencySymbol: "$",
        onChange: ()=>{},
        value: "0.00",
        editable: true,
        textSize: "",
        inputSize: "wide"
    }

    handleChangeAmount = e => {
        const {onChange} = this.props
        const value = handleChangeCurrencyValue(e)
        if (value === undefined) return
        onChange(value)
    }

    render(){
        const {currency, currencySymbol, value, editable, error, onKeyDown, onBlur, max, inputSize, textSize} = this.props
        
        return (
            <div className={`width-100pct amount flex ${inputSize} ${textSize}`}>
            <label>{`${currency} ${currencySymbol}`}</label> 
            <input
                className={`input ${error ? "error": ""} ${editable ? "": "readOnly"}`}
                readOnly={!editable}
                value={value}
                inputMode="decimal"
                onChange={editable ? this.handleChangeAmount: ()=>{}}
                onKeyDown={onKeyDown}
                min={0}
                max={max}
                onFocus={e => e.target.select()}
                onBlur={onBlur}
            />
            </div>
        )
    }

}

export default CurrencyInput