import React from "react"
import Modal from "../../components/Modal"
import CardProcessingStatus from "../../components/CardProcessingStatus";

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import styles from "./FacCardPaymentModal.module.css"
import {baseUrl} from "../../config/firebase"

import {
    CARD_AUTH_STATUS_CREATED, 
    CARD_AUTH_STATUS_CANCELLED,
    CARD_AUTH_STATUS_COMPLETED,
    CARD_RESPONSE_TYPE_PROCESSOR_DECLINED,
    CARD_RESPONSE_TYPE_PROCESSOR_ERROR,
    CARD_RESPONSE_TYPE_ISSUER_DECLINED,
    CARD_RESPONSE_TYPE_ISSUER_ERROR,
    CARD_STATUS_ERROR,
    CARD_STATUS_APPROVED,
    CARD_STATUS_DECLINED,
    CARD_STATUS_TIME_OUT,
    CARD_STATUS_LOADING
} from "../../constants/cardProcessing"

import {MINUTE_IN_MILLISECONDS} from "../../constants/datetime"
import {capitalizeAllWords} from "../../utils/stringUtils"
import {getStatusMessageFromIsoResponseCode} from "../../utils/cardProcessing"
class FacCardPaymentModal extends React.Component{
    /**
     * Purpose: display a hosted payment page from our card processor (FAC) to allow the user to securely transmit their card details
     *  
     * 1: authorize one display of the hosted payment page and receive the redirect data for the iframe
     * 2: use the redirect data to display the hosted payment page in the iframe
     * 3: display a status message to alert the user that their payment was approved, declined or errored
     */
    constructor(props){
        super(props)
        this.submit = React.createRef()
        this.submitInterval = null
        this.state = {
            redirectData: "",
            cardAuthorizationRequestId: "",
            cardProcessingStatus: "",
            statusMessage: "",
            timeout: null,
            autoCloseTimeout: null,
            cardAuthRequestListener: () => {}
        }
        this.loadingTimeout = null
    }

    componentDidMount = async () => {
        //load the hosted payment page on mount with 10 second timeout
        this.setStatus(CARD_STATUS_LOADING, false, 10000)
        this.getIframeRedirectData()
    }

    componentDidUpdate(prevProps, prevState){
        const { cardAuthorizationRequestId} = this.state
        //if after we save the card auth request id,
        //then listen to that object in the database
        const prevCardAuthRequest = prevProps.cardAuthorizationRequests.authRequestsById[cardAuthorizationRequestId]
        const cardAuthRequest = this.props.cardAuthorizationRequests.authRequestsById[cardAuthorizationRequestId]
        if (
            !prevState.cardAuthorizationRequestId &&
            cardAuthorizationRequestId 
        ){
            this.subscribeToCardAuthRequest(cardAuthorizationRequestId)
        } 
        //if there is a change in status
        else if (
            prevCardAuthRequest &&
            cardAuthRequest &&
            prevCardAuthRequest.currentStatus !== cardAuthRequest.currentStatus
        ){
            this.updateCardProcessingStatusFromAuthRequest()
        }
    }

    componentWillUnmount(){
        //remove any listeners to the card authorization request object
        this.state.cardAuthRequestListener()
        //remove the timeouts
        clearTimeout(this.state.timeout)
        clearTimeout(this.state.autoCloseTimeout)
        clearTimeout(this.loadingTimeout)
    }

    setStatus = (cardProcessingStatus, autoClose=false, maxLoadingDisplayTime=4000, statusMessage="", statusTitle="") => {
        const {closeModal, actions} = this.props
        clearTimeout(this.loadingTimeout)
        if (cardProcessingStatus === CARD_STATUS_LOADING){
            actions.toggleLoading(true)
            this.loadingTimeout = setTimeout(() => actions.toggleLoading(false), maxLoadingDisplayTime)
        } else actions.toggleLoading(false)
        this.setState({cardProcessingStatus, statusMessage})
        //if we are supposed to automatically close this modal, then do so here
        if (autoClose) {
            this.setState({autoCloseTimeout: setTimeout(closeModal, 5000)})
        }
    }

    getIframeRedirectData = async () => {
        //get the data to display the hosted payment page
        try {
            const {reference, objectId, objectType, amount2DecimalPlaces, userId, userName, email="", phoneNumber="", deviceId="", payerId="", paymentProviderId} = this.props
            const link = `${baseUrl}v1/payments/fac/authorize-hosted-payment-page`
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    amountInXcd: amount2DecimalPlaces,
                    reference,
                    objectId,
                    objectType,
                    userId,
                    userName: userName ? capitalizeAllWords(userName) : "",
                    email: email ? email : "",
                    phoneNumber: phoneNumber ? phoneNumber : "",
                    payerId,
                    deviceId,
                    paymentProviderId
                })
                
            })
            if (!response.ok) {
                this.setStatus(CARD_STATUS_ERROR)
            } else {
                const data = await response.json()
                const {redirectData, cardAuthorizationRequestId} = data
                this.setState({
                    redirectData, 
                    cardAuthorizationRequestId,
                    timeout: setTimeout(
                        //in 5 minutes, if there is not yet a card processing status
                        //then set the status to timed out
                        () => {
                            if (!this.state.cardProcessingStatus){
                                this.setStatus(CARD_STATUS_TIME_OUT)
                            }
                        }, 5 * MINUTE_IN_MILLISECONDS
                    ) 
                })
            }
            return true
        } catch (e){
            console.warn(e)
            this.setStatus(CARD_STATUS_ERROR)
        }
    }

    subscribeToCardAuthRequest = async cardAuthorizationRequestId => {
        const {actions} = this.props
        const cardAuthRequestListener = await actions.fetchSubscribeToCardAuthorizationRequest(cardAuthorizationRequestId)
        this.setState({cardAuthRequestListener})
    }

    updateCardProcessingStatusFromAuthRequest(){
        /**
         * Translate card authorization request statuses to card processing statuses
         */
        const {cardAuthorizationRequestId} = this.state
        const {cardAuthorizationRequests, onSuccess=()=>{}} = this.props
        const cardAuthRequest = cardAuthorizationRequests.authRequestsById[cardAuthorizationRequestId]
        if (!cardAuthRequest) return
        if (cardAuthRequest.currentStatus === CARD_AUTH_STATUS_COMPLETED) {
            //if the transaction has been approved, then show approval and close the modal
            this.setStatus(CARD_STATUS_APPROVED, true)
            onSuccess()
        } else if (cardAuthRequest.currentStatus === CARD_AUTH_STATUS_CANCELLED){
            //if the status was cancelled, it can either be due to an error or due to a decline
            if (
                cardAuthRequest.lastResponse === CARD_RESPONSE_TYPE_ISSUER_DECLINED ||
                cardAuthRequest.lastResponse === CARD_RESPONSE_TYPE_PROCESSOR_DECLINED
            ) this.setStatus(CARD_STATUS_DECLINED, false, 4000, getStatusMessageFromIsoResponseCode(cardAuthRequest))
            else if (
                cardAuthRequest.lastResponse === CARD_RESPONSE_TYPE_ISSUER_ERROR ||
                cardAuthRequest.lastResponse === CARD_RESPONSE_TYPE_PROCESSOR_ERROR ||
                !cardAuthRequest.lastResponse
            ) this.setStatus(CARD_STATUS_ERROR)
        }
    }

    handleIframeLoad = e => {
        /**
         * This function determines why the iframe is triggering onLoad and changes the card processing status appropriately
         * 
         * The iframe onLoad event fires 
         * - at the initial load of the iframe
         * - any time the src or srcDoc content within the iframe changes 
         * - any time a refresh is triggered in the iframe, e.g. due to submitting a form
         * 
         * Based on the user's payment interaction they can have many refreshes, 
         * e.g. frictionless/with challenge or with correct answer to challenge/with wrong answers
         * A challenge flow with only correct answer will trigger up to 7 onLoads 
         * 
         * Therefore, we can only trust the first onLoad, which is always for the initial load of the form
         * For all other onloads, we show the loading screen for 4 seconds.
         * 
         * If we are right, the buyer waits instead of being confused and potentially closing the form etc
         * otherwise, the buyer waits 4 seconds before their next interaction e.g. when there is a challenge 
         */

        //1. handle the initial load of the form, 
        //   in this case, the previous card processing status would be LOADING
        //   so we clear the status after loading completes
        //   we also set a formLoaded flag so we know the form has loaded
        if (!this.formLoaded) {
            this.formLoaded = true
            this.setStatus("")
        }
        //2. on all subsequent onloads, we assume the form has been submitted or a challenge has been submitted 
        //   and we block the user for 4 seconds
        //   if we are wrong, and they want to interact, e.g. when a challenge is displayed, then they can wait 4 seconds
        else this.setStatus(CARD_STATUS_LOADING)
    }

    handleCloseModal = () => {
        const {closeModal, onCancel=()=>{}} = this.props
        onCancel()
        closeModal()
    }

    render (){
        const {
            isOpen, 
        } = this.props
        const {redirectData, cardProcessingStatus, statusMessage} = this.state
        return (
                <Modal
                    isOpen={isOpen}
                    closeModal={this.handleCloseModal}
                    className={`height-fit-content ${styles.modal}`}
                    innerClassName={`${styles.modalInnerContainer}`}
                    overlayClassName={`flex-center ${styles.modalOverlay}`}
                >   
                    <div className={styles.container}>
                    {
                        //if there is a status that is not loading then display it
                        cardProcessingStatus && cardProcessingStatus !== CARD_STATUS_LOADING?
                        <CardProcessingStatus 
                            status={cardProcessingStatus}
                            statusMessage={statusMessage}
                        />
                        :
                        redirectData ? 
                        
                            <iframe 
                                width="310px" 
                                height="480px" 
                                srcDoc={redirectData} 
                                title="Pay By Card" 
                                frameBorder="0" 
                                allowFullScreen
                                onLoad={this.handleIframeLoad}
                            >   
                            </iframe>
                        :
                        ""
                    }
                    </div>
                </Modal>
        )
    }
}


const mapStateToProps = state => ({
    cardAuthorizationRequests: state.cardAuthorizationRequests
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FacCardPaymentModal);