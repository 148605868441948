import React, {useEffect, useState} from "react";

//screens
import Main from "../../screens/Main"
import Home from "../../screens/Home"
import Login from '../../screens/Login';
import CreateAccount from "../../screens/CreateAccount"
import The404Page from "../../screens/The404Page"
import Invoice from '../../screens/Invoice';
import Pay from '../../screens/Pay'
import Receipt from '../../screens/Receipt'

//merchant screens
import Transactions from '../../screens/MerchantScreens/Transactions';
import Invoices from '../../screens/MerchantScreens/Invoices';
import Payouts from '../../screens/MerchantScreens/Payouts';
import Customers from '../../screens/MerchantScreens/Customers';

//layouts
import AppLayout from "../../layouts/AppLayout";
import MainLayout from '../../layouts/MainLayout';
import PayLayout from '../../layouts/PayLayout';
import PayeeHomeLayout from '../../layouts/PayeeHomeLayout';

import {Route, createBrowserRouter, createRoutesFromElements, RouterProvider} from "react-router-dom"

//redux
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"

import {selectedEnvironment, useBackendEmulator} from "../../config"
import {version} from "../../config/release"

//sentry 
import {initScope, unsubscribeScope} from '../../config/sentry'

initScope()

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppLayout />}>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Main />} />
        <Route path="login" element={<Login />} />
        <Route path="create-account" element={<CreateAccount />} />
        <Route path="*" element={<The404Page />} />
      </Route>
      <Route path="404" element={<The404Page />} />
      <Route path="/home" element={<PayeeHomeLayout />}>
        <Route index element={<Home />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="payouts" element={<Payouts />} />
        <Route path="customers" element={<Customers />} />
      </Route>
      <Route path="/" element={<PayLayout />}>
        <Route path="/:payeeHandleId" element={<Pay />}/>
        <Route path="/:payeeHandleId/pay/:invoiceId" element={<Invoice />} />
        <Route path="/:payeeHandleId/r/:receiptId" element={<Receipt />} />
      </Route>
    </Route>
  )
);
                                     
function App({system, device, user, actions, }) {
  const [sessionListener, setSessionListener] = useState(() => () => {})
  //set up & log version and environment on startup
  useEffect(() => {
    if (selectedEnvironment !== system.environment) actions.setEnvironment()
    console.log(`Environment: ${selectedEnvironment}`)
    if (useBackendEmulator) console.log("Backend emulator: active")
    if (version !== system.version) {
      actions.setVersion()
      console.log(`Version has changed from ${system.version} to version ${version}`)
    }
    console.log(`Version: ${version}`)
    //track whether a user is logged in or out and sync actions across tabs
    setSessionListener(() => actions.trackUserSession())
    return () => {
      //unsusbscribe Sentry
      unsubscribeScope()
      //avoid any permanent loading bugs
      actions.toggleLoading(false)
      //unsubscribe from session listener
      sessionListener()
    }
  }, []);
  //set up device on start up
  useEffect(() => {
    if (device.id) actions.fetchUpdateDevice(device.id)
    else actions.fetchCreateDevice()
  }, [])
  //listen to the user to reflect changes like role adjustments
  const [userListener, setUserListener] = useState(() => () => {})
  const listenToUser = async () => {
    const newUserListener = await actions.fetchSubscribeToUser(user.id)
    setUserListener(() => newUserListener)
  }
  useEffect(() => {
    if (user.id && user.authenticated) listenToUser()
    return () => {
      if (typeof userListener === "function") userListener()
    }
  }, [user.id, user.authenticated])
  return (
        <RouterProvider router={router} />
  )
}

const mapStateToProps = state => ({
  system: state.system,
  user: state.user,
  device: state.device,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
