import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"

export const SAVE_CARD_AUTHORIZATION_REQUESTS = 'SAVE_CARD_AUTHORIZATION_REQUESTS'

export const saveCardAuthorizationRequests = authRequests => {
    return {
        type: SAVE_CARD_AUTHORIZATION_REQUESTS,
        payload: {
            authRequests
        }
    }
}

export const fetchSubscribeToCardAuthorizationRequest = cardAuthorizationRequestId => {
    /**
      * Purpose: listen to a card authorization request from the firestore database
      * Note: the onSnapshot below watches for changes to the center on the server
      */
    const firestore = getFirestore(firebaseApp);
    const cardAuthorizationRequestsRef = doc(firestore, "cardAuthorizationRequests", cardAuthorizationRequestId)
                      
    return async dispatch => {
        try {
            const cardAuthorizationRequestListener = await onSnapshot(cardAuthorizationRequestsRef,
                docRef => {
                    //get a card authorization request from the snapshot
                    const cardAuthorizationRequest = docRef.data();
                    dispatch(saveCardAuthorizationRequests([cardAuthorizationRequest]))
            })
            return cardAuthorizationRequestListener
        } catch (e){
            const message = `action > cardAuthorizationRequests > fetchSubscribeToCardAuthorizationRequest: Failed to save cardAuthorizationRequest with id ${cardAuthorizationRequestId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}