import React from "react"
import styles from "./Navigation.module.css"
import Logo from "../../images/logo100x143.png"
import {Link} from "react-router-dom"

const Navigation = ({children=[], backgroundColor=""}) => {
    return (
        <div className={styles.container}>
            <header style={{backgroundColor}} className="topBar">
                <div className="navContainer">
                    <div className="navHeader">
                        <Link to="/">
                            <div><img className="navLogo" src={Logo} alt="SHOPDM PAY"/></div>
                        </Link>
                    </div>
                    <nav className="navMenu">
                        {children}
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navigation