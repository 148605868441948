import React from "react"
import styles from "./ActivityListItem.module.css"

const ActivityListItem = ({
    reason, 
    title, 
    details="",
    left="",
    right="",
    onClick=undefined
}) => { 
    const clickStyles = onClick ? styles.clickable : ""
    return (
    <div
        onClick={onClick ? onClick : () => {}}
        className={`${styles.container} ${clickStyles}`}
    >
        <div className={styles.left}>{left}</div>
        <div className={styles.description}>
                <div>{title}</div>
                <div className={styles.reason}>{reason}</div>
                {
                    details ?
                    <div className={styles.details}>{details}</div>
                    :
                    null
                }
        </div>
        <div className={styles.right}>
                {right}
        </div>
        
    </div>
    )
}

export default ActivityListItem