import {firebaseApp} from "../config/firebase"
import {getFirestore, query, collection, where, onSnapshot, getAggregateFromServer, sum} from "firebase/firestore"
import { logError } from "../utils/errorHandlingUtils"
import { DAY_IN_MILLISECONDS } from "../constants/datetime";

export const SAVE_PAYOUTS = 'SAVE_PAYOUTS'

export const savePayouts = payouts => {
    return {
        type: SAVE_PAYOUTS,
        payload: {
            payouts
        }
    }
}

export const fetchSubscribeToPayeePayoutsInDateRange = (
    payeeId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payouts placed in the last 30 days
    toDate=Date.now() + (DAY_IN_MILLISECONDS * 30) //default to a month into the future
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(
                            collection(firestore, "payouts"), 
                            where("payeeId", "==", payeeId), 
                            where("createdAt", ">=",  fromDate),
                            where("createdAt", "<=", toDate)
                        )
    return async (dispatch) => {
        try{
            const payoutsListener = await onSnapshot(payoutsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayouts(payouts))
                }
            )
            return payoutsListener
        } catch (e){
            const message = `action > payouts > fetchSavePayeePayoutsInDateRange: Failed to save payouts for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToPayeePayoutsPaidInDateRange = (
    payeeId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all payouts paid in the last 30 days
    toDate=Date.now() + (DAY_IN_MILLISECONDS * 30) //default to a month into the future
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(
                            collection(firestore, "payouts"), 
                            where("payeeId", "==", payeeId), 
                            where("paidAt", ">=",  fromDate),
                            where("paidAt", "<=", toDate)
                        )
    return async (dispatch) => {
        try{
            const payoutsListener = await onSnapshot(payoutsRef,
                querySnapshot =>{
                    //get an array of products from the snapshot
                    const payouts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayouts(payouts))
                }
            )
            return payoutsListener
        } catch (e){
            const message = `action > payouts > fetchSubscribeToPayeePayoutsPaidInDateRange: Failed to save payouts for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSumPayeeUnpaidPayouts = payeeId => {
    const firestore = getFirestore(firebaseApp)
    const payoutsRef = query(
                            collection(firestore, "payouts"), 
                            where("payeeId", "==", payeeId), 
                            where("paid", "==",  false)
                        )
    return async () => {
        try{
            const snapshot = await getAggregateFromServer(payoutsRef, {
                sumOfUnpaidPayouts: sum('totalXcd')
            });
            return snapshot.data().sumOfUnpaidPayouts
        } catch (e){
            const message = `action > payouts > fetchSumPayeeUnpaidPayouts: Failed to add up unpaid payouts for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return 0
        }
    }
}