import {firebaseApp} from "../config/firebase"
import {getFirestore, writeBatch, doc, getDoc, updateDoc, onSnapshot} from "firebase/firestore"
// import { getMessaging, deleteToken, isSupported} from "firebase/messaging";
import { 
    getAuth, 
    onAuthStateChanged, 
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut, 
    createUserWithEmailAndPassword, 
    sendPasswordResetEmail,
    GoogleAuthProvider,
} from "firebase/auth";
import { logError } from "../utils/errorHandlingUtils"

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SAVE_USER = 'SAVE_USER'
export const SET_ACTIVE_PAYEE_ACCOUNT = 'SET_ACTIVE_PAYEE_ACCOUNT'

export const saveUser = user => {
    return {
        type: SAVE_USER,
        payload: {
            user
        }
    }
}

export const login = user => {
    return {
        type: LOGIN,
        payload: {
            user
        }
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export const setActivePayeeAccount = activePayeeAccountId => {
    return {
        type: SET_ACTIVE_PAYEE_ACCOUNT,
        payload: {
            activePayeeAccountId
        }
    }    
}

export const fetchSubscribeToUser = userId => {
    /**
     * Purpose: subscribe to the specified user
     *          onSnapshot watches for changes in the db
     */
    return async dispatch => {
        try {   
            const firestore = getFirestore(firebaseApp)
            const userRef = doc(firestore, 'users', userId)
            const userListener = await onSnapshot(userRef,
                docRef => {
                    const user = docRef.data()
                    dispatch(saveUser(user))
                })
            return userListener
        } catch(e){
            const message = `action > user > fetchSubscribeToUser: Failed to subscribe to user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

async function fetchLogin(
    userId,
    isGoogleLogin=false,
    lastLogin=Date.now()
) {
    try {
        const firestore = getFirestore(firebaseApp);
        const userRef = doc(firestore, "users", userId);
        const batch = writeBatch(firestore);
        const update = {
            lastLogin,
            lastVisitedPaydmAt: Date.now(),
        }
        if (isGoogleLogin) update.lastGoogleLogin = lastLogin
        batch.set(
            userRef,
            update,
            {merge: true}
        );
        await batch.commit();
        const docRef = await getDoc(userRef);
        const user = docRef.data();
        // const analytics = getAnalytics(firebaseApp);
        // setUserId(analytics, userId);
        // setUserProperties(analytics, {userId: userId});
        return user;
    } catch (e) {
        const message = `action > user > fetchLogin: Failed to save user to database ${userId}`
        if (e.message_){
            //deal with firebase-specific errors
            logError(new Error(`${e.message} ${message}`))
        } else {
            e.message = `${e.message} ${message}`
            logError(e)
        }
        return false
    }
}

export const fetchLoginWithEmailAndPassword = (email, password, onSuccess=()=>{}, onError=()=>{}) => {
    /**
     * Purpose: log the user in and save his userId
     */
    return async (dispatch, getState) => {
        try {
            const authenticated = await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp);
                signInWithEmailAndPassword(auth, email, password)
                    .then((user) => resolve(user))
                    .catch((err) => reject(err));
            });
            // const {device} = getState();

            const loggedInUser = await fetchLogin(
                authenticated.user.uid,
            );
            if (loggedInUser) {
                dispatch(login({...loggedInUser, authenticated: true}))
            } else {
                throw new Error(`Failed to log in user ${authenticated.user.id}`);
            }
            onSuccess(loggedInUser)
            return true;
        } catch (e){
            const message = `action > user > fetchLoginWithEmailAndPassword: Failed to login email ${email}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError({email, password, errorCode: e.code})
            return false
        }
    }
}

export const trackUserSession = () => {
    return async (dispatch, getState) => {
        try {
            const auth = getAuth(firebaseApp)
            const sessionListener = onAuthStateChanged(auth, function(authenticated) {
                console.log("firing onAuthStateChanged, value is ", authenticated)
                console.log("auth value is ", auth)
                if (authenticated) {
                    setTimeout(async () => {
                        const {user} = getState()
                        if (
                            !user.authenticated && 
                            user.lastLogin === 0 &&
                            authenticated.uid   
                        ){
                            const firestore = getFirestore(firebaseApp)
                            const userRef = doc(firestore, 'users', authenticated.uid)
                            const docRef = await getDoc(userRef)
                            const user = docRef.data()
                            dispatch(login({...user, authenticated: true}))
                        } 
                    }, 1000)
                } else {
                    //if the user has been logged in for more than a second locally
                    //but they are not authenticated on the server
                    console.log("auth state is null")
                    setTimeout(() => {
                        const {user} = getState()
                        if (
                            user.authenticated && 
                            user.lastLogin !== 0 &&
                            user.lastLogin < (Date.now() - 1000)
                        ){
                            logError(`auto logout of user, because auth state is ${JSON.stringify(authenticated)}`)
                            dispatch(logout())
                        }
                    }, 1000)
                }
            });
            return sessionListener
        } catch (e) {
            const message = `action > user > trackUserSession: Failed to track user session`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchLogout = () => {
    
    return async dispatch => {
        try {
            // const supported = await isSupported()
            // const messaging = supported ? getMessaging() : null;
            // //delete messaging token, so next user of this device does not get this user's notifications
            // if (messaging) deleteToken(messaging)
            await new Promise((resolve, reject) => {
                const auth = getAuth(firebaseApp)
                signOut(auth)
                    .then(() => resolve(true))
                    .catch((err) => reject(err));
            })
            .catch(e => console.warn(e))
            dispatch(logout())
            return true
        } catch (e){
            const message = `action > user > fetchLogout: Failed to logout`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSetActivePayeeAccount = (userId, activePayeeAccountId) => {
    /**
     * Purpose: set the active payee account on the current user
     */
    const firestore = getFirestore(firebaseApp)
    const userRef = doc(firestore, 'users', userId)
    return async dispatch => {
        try {   
            await updateDoc(userRef, {activePayeeAccountId})
            dispatch(setActivePayeeAccount(activePayeeAccountId))
            return true
        } catch(e){
            const message = `action > user > fetchSetActivePayeeAccount: Failed to set active payee account ${activePayeeAccountId} on user ${userId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

