/**
 *  Purpose: the modifications that are carried on the System object for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {selectedEnvironment} from "../config"
 import {version} from "../config/release"
 
 // STATE
 // system:{
 //  environment: null,
 //  version: null
 //  loading: false,
 //  loadingText: "",
 // }
 
 
 const systemReducer = (state = initialState.system, action) => {
     const {type, payload} = action;
 
     switch (type){
        case types.TOGGLE_LOADING: {
            return {
                ...state,
                loading: payload.loading,
                loadingText: payload.loadingText
            }
        }
        case types.CHANGE_ENVIRONMENT: {
            return {
                ...state,
                environment: selectedEnvironment
            }
        }

        case types.CHANGE_VERSION: {
            return {
                ...state,
                version
            }
        }

        default: return state
     }
 }
 
 export default systemReducer