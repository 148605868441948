import * as Sentry from '@sentry/browser';

export const logError = (error, errorInfo = null) => {
    if (process.env.NODE_ENV !== 'production') {
        console.warn(error)
    } else {
        if (typeof error === "string") error = new Error(error)
        Sentry.captureException(error, errorInfo);
    }
}

export const simplifyCallableErrorCode = (error) => {
    if (error.code && error.code.includes("functions/")) {
        error.code = error.code.replace("functions/", "")
    }

    return error;
}

export const isCallableError = (error) => !!error.code;

export const isInternalCallableError = (error) => isCallableError(error) && (
    error.code.includes("unknown") || error.code.includes("internal")
)
