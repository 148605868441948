import React from "react";

import Icon from "../Icon";
import InvoiceBadge from "../InvoiceBadge";
import ActivityListItem from "../ActivityListItem";
import InvoiceQuickActionButton from "../InvoiceQuickActionButton";
import InvoiceActionsMenu from "../../containers/InvoiceActionsMenu";

import {capitalize} from "../../utils/stringUtils";
import {INVOICE_STATUS_VIEWED} from "../../constants/invoices";

import {timestampToDateString} from "../../utils/datetimeUtils";
import {numberToCurrencyString} from "../../utils/currencyUtils";

const InvoiceActivityListItem = (props) => {
    const {
        invoice = {}, payeeHandleId = "",
    } = props;

    const {
        lastViewedAt,
        reason = "",
        totalXcd = "0",
        invoiceNumber = "",
        currentStatus = "",
        billToCustomer={}
    } = invoice
    const {name=""} = billToCustomer
    return (
        <ActivityListItem
            left={<div style={{"backgroundColor": "var(--info-blue)"}} className="innerIconContainer"><Icon
                icon="receipt"/></div>}
            title={<div className="margin-bottom-05em">${numberToCurrencyString(totalXcd)} Invoice {name ? `to ${name}`: ""} {invoiceNumber} </div>}
            reason={<div>
                        <div ><InvoiceBadge currentStatus={currentStatus}/></div>
                        <div>{capitalize(reason)}</div>
                </div>}
            details={
                currentStatus === INVOICE_STATUS_VIEWED && lastViewedAt ?
                    <div className="color-grey">Last viewed
                        on {timestampToDateString(lastViewedAt, "EEE, dd MMMM 'at' t")}</div>
                    :
                    ""
            }
            right={
                <div className="display-flex align-items-center">
                    <InvoiceQuickActionButton invoice={invoice} payeeHandleId={payeeHandleId}/>
                    <div className="margin-left-05em">
                        <InvoiceActionsMenu invoice={invoice}
                                            payeeHandleId={payeeHandleId}
                        />
                    </div>
                </div>
            }
        />
    )
}

export default InvoiceActivityListItem
