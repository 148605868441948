/**
 *  Purpose: the modifications that are carried out on the payeeProduct objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // payeeProducts:{
 //  payeeProductsById:{},
 // }
 
 
 const payeeProductsReducer = (state = initialState.payeeProducts, action) => {
     const {type, payload} = action;
     let payeeProductsById = {...state.payeeProductsById}
 
     switch (type){
         case types.SAVE_PAYEE_PRODUCTS: {
             if(typeof payload.payeeProducts !== "object") {
                 logError(`payeeProductsReducer > SAVE_PAYEE_PRODUCTS: payeeProducts payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.payeeProducts.forEach(payeeProduct => {
                     payeeProductsById[payeeProduct.id] = payeeProduct
             })
             
             return {
                 ...state,
                 payeeProductsById,
             }
         }
 
         case types.LOGOUT: {
             return initialState.payeeProducts
         }
         
         default: return state
     }
 }
 
 export default payeeProductsReducer