import React from "react"
import Icon from "../components/Icon"
import PopoverWithArrow from "../components/PopoverWithArrow";

const withPopover = (Component) => {
    return function WithPopover (props) {
        const {popoverIcon="", popoverText=""} = props
        //control popover
        const [anchorEl, setAnchorEl] = React.useState(null);

        const showPopover = e => {
            setAnchorEl(e.currentTarget);
            setTimeout(() => setAnchorEl(null), 1000)
        }
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <div>
                <Component 
                    {...props}
                    showPopover={showPopover}
                />
                <PopoverWithArrow
                    id={id}
                    open={open} 
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                >
                    <div className="padding-05em font-size-14px icon-left display-flex">
                        {popoverIcon ? <Icon icon={popoverIcon}/> : null}
                        {popoverText}
                    </div> 
                </PopoverWithArrow>
            </div>
        )
    }
}

export default withPopover