import { useState, useEffect } from 'react';

import { DAY_IN_MILLISECONDS } from "../constants/datetime";

function useBalance({payeeId, payouts, actions}){
    /** 
     * Purpose: calculate a payee's balance, to be paid out at the next disbursement
     * 
     * Approach:
     * 
     * 1. use sum aggregation to calculate the current balance. 
     * 2. listen to the last created payout to know when the current balance has gone up
     * 3. listen to the last paid payout to know when the current balance has gone down
     *  */

    //1. use sum aggregation to calculate the current balance. 
    const [balance, setBalance] = useState(0)
    const getSumOfUnpaidPayouts = async () => {
        const newBalance = await actions.fetchSumPayeeUnpaidPayouts(payeeId)
        setBalance(newBalance)
    }

    //2. listen to the last created payout to know when the current balance has gone up
    
    //implement by listening to any payouts for this payee made in the last day
    const [todaysPayoutsListener, setTodaysPayoutsListener] = useState(() => () => {})
    const listenToPayouts = async () => {
        const newTodaysPayoutsListener = await actions.fetchSubscribeToPayeePayoutsInDateRange(
            payeeId, 
            Date.now() - DAY_IN_MILLISECONDS,
            Date.now() + DAY_IN_MILLISECONDS
        )
        setTodaysPayoutsListener(() => newTodaysPayoutsListener)
    }
    useEffect(() => {
        if (payeeId) listenToPayouts()
        return () => {
            if (typeof todaysPayoutsListener === "function")todaysPayoutsListener()
        }
    }, [payeeId])
    
    //keep track of the last created payout id. This will allow us to refresh 
    //the server-side summing the unpaid payouts when new payouts are created 
    const lastCreatedPayoutId = payouts.payeeStats && payouts.payeeStats[payeeId] ?
                                payouts.payeeStats[payeeId].lastCreatedPayoutId
                                :
                                ""
    useEffect(() => {
        if (payeeId) getSumOfUnpaidPayouts()
    }, [payeeId, lastCreatedPayoutId])
    
    //3. listen to the last paid payout to know when the current balance has gone down

    //implement by listening to any payouts for this payee paid in the last day going forward 30 days
    const [payoutsPaidTodayListener, setPayoutsPaidTodayListener] = useState(() => () => {})
    const listenToPayoutsPaidToday = async () => {
        const newPayoutsPaidTodayListener = await actions.fetchSubscribeToPayeePayoutsPaidInDateRange(
            payeeId, 
            Date.now() - DAY_IN_MILLISECONDS,
            Date.now() + (DAY_IN_MILLISECONDS * 30)
        )
        setPayoutsPaidTodayListener(() => newPayoutsPaidTodayListener)
    }
    useEffect(() => {
        if (payeeId) listenToPayoutsPaidToday()
        return () => {
            if (typeof payoutsPaidTodayListener === "function") payoutsPaidTodayListener()
        }
    }, [payeeId])
    //keep track of the last paid payout id. This will allow us to refresh 
    //the server-side summing the unpaid payouts when new disbursements are made and payouts are paid 
    const lastPaidPayoutId = payouts.payeeStats && payouts.payeeStats[payeeId] ?
                                payouts.payeeStats[payeeId].lastPaidPayoutId
                                :
                                ""
    useEffect(() => {
        if (payeeId) getSumOfUnpaidPayouts()
    }, [payeeId, lastPaidPayoutId])

    return balance
}

export default useBalance