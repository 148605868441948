export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT'
export const CHANGE_VERSION = 'CHANGE_VERSION'

export const setEnvironment = () => {
    return {
        type: CHANGE_ENVIRONMENT
    }
} 

export const setVersion = () => {
    return {
        type: CHANGE_VERSION
    }
} 

export const toggleLoading = (loading, loadingText="") => {
    return {
        type: TOGGLE_LOADING,
        payload: {
            loading,
            loadingText
        }
    }
}