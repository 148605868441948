/**
 *  Purpose: the modifications that are carried on the Payout objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 import {STATISTICS_IDENTIFIER} from "../constants/analysis"
 // STATE
 // payouts:{
 //  payoutsById:{},
 //  stats: {}
 //  payeeStats: {}
 // }
 
 
 const payoutsReducer = (state = initialState.payouts, action) => {
     const {type, payload} = action;
     let payoutsById = {...state.payoutsById}
     let stats = {...state.stats}
     let payeeStats = {...state.payeeStats}
     switch (type){
         case types.SAVE_PAYOUTS: {
             if(typeof payload.payouts !== "object") {
                 logError(`payoutsReducer > SAVE_PAYOUTS: payouts payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.payouts.forEach(payout => {
                 if (payout.id !== STATISTICS_IDENTIFIER){
                     payoutsById[payout.id] = payout
                 } else stats = payout
                 //add payee stats 
                 const {payeeId, paidAt, createdAt} = payout
                 if (!payeeStats[payeeId]) payeeStats[payeeId] = {}
                 //update the lastPaidPayoutId to be the last one that was paid
                 if (paidAt && (!payeeStats[payeeId].lastPaidPayoutAt || paidAt > payeeStats[payeeId].lastPaidPayoutAt )){
                    payeeStats[payeeId].lastPaidPayoutId = payout.id
                    payeeStats[payeeId].lastPaidPayoutAt = paidAt
                 }
                //update the lastCreatedPayoutId to be the last one that was created
                if (!payeeStats[payeeId].lastCreatedPayoutAt || createdAt > payeeStats[payeeId].lastCreatedPayoutAt ){
                    payeeStats[payeeId].lastCreatedPayoutId = payout.id
                    payeeStats[payeeId].lastCreatedPayoutAt = createdAt
                 }                                                    
             })
             
             return {
                 ...state,
                 payoutsById,
                 stats,
                 payeeStats
             }
         }
 
         case types.LOGOUT: {
             return initialState.payouts
         }
 
         default: return state
     }
 }
 
 export default payoutsReducer